import React from "react";
import { Translate } from "../../../utils/Translate";


export default function NewCard() {
  return (
    <label className="featured-labelNew livecard-label live-label">
      {Translate({ id: "ClassListViews.new" })}
    </label>
  );
}

