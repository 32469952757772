import { useDispatch, useSelector } from "react-redux";
import { FetchRecordedClasses, FetchStreamingLink, clearLiveStreamRecordedData,liveStreamStarted, logOnDemandTracking, getRecordedEventData, resetStreamingLink } from "../store/actions/ondemand"
import { GetUserSubscription } from "../store/actions/subscription";
import { redirectForSSO } from '../store/actions/auth';
import { NOTIFY_USER, SAVE_DEEPLINK_PAYLOAD } from "../store/actions/actionTypes";
import { postWorkoutResult, sendLiveStreamPlayDurationToLocalytics } from './../store/actions/workout';
import { getPayload } from '../utils/LogClassUtilites';
import { Translate } from '../utils/Translate';
import { setNavigatedFromWhichScreen } from '../store/actions/auth';
import {
  getShortDeepLink,
  resetShortDeepLink,
} from "../store/actions/dynamicDeeplink";
const useLiveStreamPlayer = () => {
    const dispatch = useDispatch();
    const useLiveStreamPlayerState = () => ({
      recordedClasses: useSelector((state) => state.onDemand.RecordedClasses),
      StreamingVideoLink: useSelector(
        (state) => state.onDemand?.StreamingVideoLink
      ),
      eventData:useSelector((state)=> state.onDemand?.eventData),
      loading: useSelector((state) => state.onDemand.loading),
      streamingVideo: useSelector((state) => state.onDemand.StreamingVideo),
      isSubscriptionLoading: useSelector(
        (state) => state.subscription.isSubscriptionLoading
      ),
      hasPlayerAccess: useSelector(
        (state) => state.subscription.hasPlayerAccess
      ),
      navigatedFromWhichScreen: useSelector(
        (state) => state.auth.navigatedFromWhichScreen
      ),
      fetchShortLinkLoading:
        useSelector((state)=>state.dynamicDeeplink.fetchShortLinkLoading),
      dynamicShortLink: useSelector((state)=>state.dynamicDeeplink.dynamicShortLink),
    });
    const useLiveStreamPlayerDispatch = () => ({
      clearLiveStreamRecordedData: () => {
        dispatch(clearLiveStreamRecordedData());
      },
      liveStreamStarted: (isLiveStreamStarted) => {
        dispatch(liveStreamStarted(isLiveStreamStarted));
      },
      getUserSubscription: () => {
        dispatch(GetUserSubscription());
      },
      fetchStreamingLink: (classId) => {
        dispatch(FetchStreamingLink(classId));
      },
      getRecordedEventData: (event_Id) =>{
        dispatch(getRecordedEventData(event_Id))
      },
      fetchRecordedClasses: (
        event_Id,
        nextOccurence = "",
        videoId = "",
        showLoading
      ) => {
        dispatch(
          FetchRecordedClasses(event_Id, nextOccurence, videoId, showLoading)
        );
      },
      saveDeeplinkPayload: (path) => {
        dispatch({ type: SAVE_DEEPLINK_PAYLOAD, deeplinkData: path });
      },
      setNavigatedFromWhichScreen: (screen = "") => {
        dispatch(setNavigatedFromWhichScreen(screen));
      },
      redirectForSSO: (callbackFn) => {
        dispatch(redirectForSSO(callbackFn));
      },
      liveEventEnded: () => {
        dispatch({
          type: NOTIFY_USER,
          NotificationText: Translate({ id: "liveStream.LiveEventEnded" }),
        });
      },
      postWorkoutResult: (cardDetails, time) => {
        dispatch(postWorkoutResult(getPayload(cardDetails, 9, parseInt(time))));
      },
      sendLiveStreamDurationToLocalytics: (
        playerCurrentTime,
        recordedClasses
      ) => {
        dispatch(
          sendLiveStreamPlayDurationToLocalytics(
            playerCurrentTime,
            recordedClasses
          )
        );
      },
      logOnDemandTracking: (cardDetails, userId, starter) => {
        dispatch(
          logOnDemandTracking(
            cardDetails,
            userId,
            starter,
            cardDetails?.isClubConnect ?? false
          )
        );
      },
      getShortDeepLink: (classType, classDetails, configJson) =>
        dispatch(getShortDeepLink(classType, classDetails, configJson)),
      resetShortDeepLink: () => dispatch(resetShortDeepLink()),
      resetStreamingLink: () => dispatch(resetStreamingLink()),
    });

    const initialState = {
        FLLStreamingVideoLink : null,
        cardDetails : null ,
        eventStatus : null ,
        bindedPlayer: null ,
        showLeaveModal : false,
        showImageLayoutOnly : false,
        videoInitialised : false,
        duration : 0,
        eventId :null,
        pageShared : false,
        videoEnded : false,
        showAwaitError : false,
        showPopUPforSSO : false,
        videoStarted : false,
        fromDeepLink : true,
        liveVideoEnded : false
    }

    const useliveStreamPlayerAction = {
        UPDATE_LIVESTREAMPLAYER : "UPDATE_LIVESTREAMPLAYER"
    }

    const useLiveStreamPlayerReducer = (state, action) =>{
        const { type, payload } = action;
        switch (type) {
            case useliveStreamPlayerAction.UPDATE_LIVESTREAMPLAYER:
                return { ...state , ...payload}

            default:
                return state
        }
    }

    const setReducerData = (liveStreamPlayerDispatch,payload)=>{
        liveStreamPlayerDispatch({
            type: useliveStreamPlayerAction.UPDATE_LIVESTREAMPLAYER,
            payload: payload
        })
    }

    return {useLiveStreamPlayerState,
         useLiveStreamPlayerDispatch,
         liveStreamPlayerReducer : { initialState,useLiveStreamPlayerReducer,setReducerData }
        }
}
export {useLiveStreamPlayer}