import React, { Fragment, useEffect, useRef,useCallback, useReducer,useState } from 'react'
import { isEmpty } from 'lodash';
import "../../components/PlayerAndCarousel/Workout/Workout.css";
import Grid from "@material-ui/core/Grid";
import { Typography, Button } from "@material-ui/core";
import ReactHtmlParser from "react-html-parser";
import { getNextOccurenceDate, getTimetoStart, isSLClassStarted } from '../../utils/LivestreamTime';
import { Translate } from '../../utils/Translate';
import ClubCardNew from '../../containers/LiveConnect/ClubCardNew';
// import videojs from "video.js";

import Spinner from "../../components/spinner/spinner";
import Modal from '../../components/PlayerAndCarousel/VideoWrapper/QuitAndLogVideoUi'
import PureVideoWrapper from './LSPlayerWrapper';
import ClassCompleted from './ClassCompleted';
import logger from "../../utils/logger";
import Routes from "../../components/Router/Routes"
import Timer from 'react-compound-timer';
import { ClassType,LiveStreamStatusConstants } from "../../utils/constants";
import CustomDialog from "../../components/Modal/Modal"
import { useLiveStreamPlayer } from "../../hooks/useLiveStreamPlayer";

import "../PlayerAndCorousel/views/Player/Player.css";
import ShareView from "../../components/Share/ShareView";
import { VideoPlayer } from '../../components/VideoPlayer';
import "../PlayerAndCorousel/views/Player/Player.css"
import PlayerShimmer from '../../components/Shimmer/PlayerShimmer'
import { ShareIcon } from '../../CommonImages/ShareIcon';
import CloseGreen from 'CommonImages/closeGreen';
import { useNavigate } from 'react-router';
// import "video.js/dist/video-js.css";
const config = require("../../assets/config.json")




// window.videojs = videojs;
var starter = 0;
var curVideoTime = null;
function LiveStreamPlayer(props) {
    const navigate = useNavigate();
    const intervalForStreaming = useRef(null);
    const isAuthenticated = localStorage.getItem("token");
    const { useLiveStreamPlayerState, useLiveStreamPlayerDispatch, liveStreamPlayerReducer } = useLiveStreamPlayer();
    const { recordedClasses, StreamingVideoLink, loading,
        // streamingVideo,
        isSubscriptionLoading, hasPlayerAccess,
        navigatedFromWhichScreen,dynamicShortLink,eventData
    } = useLiveStreamPlayerState();

    const { clearLiveStreamRecordedData, liveStreamStarted, getUserSubscription,
        fetchStreamingLink, fetchRecordedClasses, saveDeeplinkPayload,
        setNavigatedFromWhichScreen, redirectForSSO, liveEventEnded,
        sendLiveStreamDurationToLocalytics, logOnDemandTracking,resetShortDeepLink,
        getShortDeepLink,getRecordedEventData,resetStreamingLink } = useLiveStreamPlayerDispatch();

    const { initialState, useLiveStreamPlayerReducer, setReducerData } = liveStreamPlayerReducer
    const [liveStreamPlayerState, liveStreamPlayerDispatch] = useReducer(useLiveStreamPlayerReducer, initialState)
    const { FLLStreamingVideoLink, cardDetails, eventStatus, bindedPlayer, showLeaveModal, showImageLayoutOnly, videoInitialised, duration, eventId, pageShared, videoEnded, showAwaitError, showPopUPforSSO, videoStarted, fromDeepLink, liveVideoEnded} = liveStreamPlayerState


    var player = null;
    const interval = useRef(null);
    const isFLLEvent = (config.connectSource && config.connectSource.toLowerCase() === "wexer") ? true : false
    const isStreamingLinkEmpty = !StreamingVideoLink
    const updateEventStatus = ()=>{
        // setEventStatus(isStreamingLinkEmpty ? LiveStreamStatusConstants.AWAITING : LiveStreamStatusConstants.STREAMING)
        const setEventStatus = isStreamingLinkEmpty ? LiveStreamStatusConstants.AWAITING : LiveStreamStatusConstants.STREAMING
        setReducerData(liveStreamPlayerDispatch,{eventStatus : setEventStatus})
    }

    const [showHideShareView, setShowHideShareView] = useState(false);
  const [dynamicLinkDetails, setDynamicLinkDetails] = useState({});

    useEffect(() => {
        return (() => {

            /**
             * Nikhil Gupta 28th Sept, 2020
             * CF-8997
             * clearing the recoded class
             * this is done to sent the same event to localytics
             * if the user navigates from player to another Page
             */

            // if (!(player && player.currentTime()))
            //     dispatch(clearLiveStreamRecordedData())
            if (bindedPlayer) {
                onVideoEnded()
                bindedPlayer.remove();
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bindedPlayer]
    );

    useEffect(() => {
        return () => {
            resetShortDeepLink();
            resetStreamingLink()
            if (!(player && player.currentTime())) {
                clearLiveStreamRecordedData()
                liveStreamStarted(false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (isAuthenticated) {
            if(!isSubscriptionLoading)getUserSubscription()
            if (props.location.state) {
                setReducerData(liveStreamPlayerDispatch,{fromDeepLink : false , eventId : (props.location.state.cardDetails.id) })

                if (!isFLLEvent) {
                    fetchStreamingLink(props.location.state.cardDetails.id)
                    fetchRecordedClasses(props.location.state.cardDetails.id, props.location.state.cardDetails.nextOccurence)
                    getRecordedEventData(props.location.state.cardDetails.id)
                    // check in case of vimeo
                    setReducerData(liveStreamPlayerDispatch,{eventStatus : isStreamingLinkEmpty ? LiveStreamStatusConstants.UNAVAILABLE : (props.location.state.cardDetails.eventStatus)})
                } else {
                    fetchRecordedClasses(props.location.state.cardDetails.id, props.location.state.cardDetails.nextOccurence, props.location.state.cardDetails.videoId)
                    fetchStreamingLink(props.location.state.cardDetails.id)
                    getRecordedEventData(props.location.state.cardDetails.id)
                    if (props.location.state.cardDetails.duration) {
                        if (
                            new Date().getTime() > new Date(props.location.state.cardDetails.nextOccurence +"T"+ props.location.state.cardDetails.activeTime).getTime() &&
                            new Date().getTime() < new Date(props.location.state.cardDetails.nextOccurence +"T"+props.location.state.cardDetails.activeTime).getTime() +
                            props.location.state.cardDetails.duration * 60 * 1000) {
                            setReducerData(liveStreamPlayerDispatch,{eventStatus : (isStreamingLinkEmpty ? LiveStreamStatusConstants.AWAITING : LiveStreamStatusConstants.STREAMING)})

                        } else {
                            setReducerData(liveStreamPlayerDispatch,{eventStatus : (LiveStreamStatusConstants.UNAVAILABLE)})

                        }
                    }
                    if (props.location.state.cardDetails.duration) {
                        checkEventEnded(props.location.state.cardDetails.duration, props.location.state.cardDetails.nextOccurence,props.location.state.cardDetails.activeTime)
                    }
                }
                setReducerData(liveStreamPlayerDispatch, { FLLStreamingVideoLink: (props.location.state.cardDetails.url ?? StreamingVideoLink), cardDetails: (props.location.state.cardDetails) })
            }
            else {
                const path = window.location.pathname;
                var pathItems = path.split("/");
                let eventId = ""
                if (pathItems.length > 2 && pathItems[1] === "live") {
                    eventId = pathItems[2]
                }
                else if (pathItems.length > 2 && pathItems[2] === "live") {
                    eventId = pathItems[3]
                }
                setReducerData(liveStreamPlayerDispatch,{eventId : eventId})
                if (!isFLLEvent) {
                    setReducerData(liveStreamPlayerDispatch,{pageShared : true})
                    fetchStreamingLink(pathItems[3])
                    //dispatch(FetchRecordedClasses(pathItems[2],props.location.state.cardDetails.nextOccurence))
                    fetchRecordedClasses(pathItems[3], "")
                }
                else {
                    setReducerData(liveStreamPlayerDispatch,{pageShared : true})
                    fetchStreamingLink(pathItems[3])
                    fetchRecordedClasses(pathItems[3])
                }
            }
        } else {
            const path = window.location.pathname;
            let isDeepLink = props.location.state;
            if (!isDeepLink) {
                saveDeeplinkPayload(path)
            }
            if (navigatedFromWhichScreen === "SIGNIN") {
                props.history.goBack()
                setNavigatedFromWhichScreen("")
                return
            }
            else
                setNavigatedFromWhichScreen("")
            var callback = () => {
                setNavigatedFromWhichScreen("SIGNIN")
                navigate("/signin", { state: path });
            }
            redirectForSSO(callback)
        }

        return () => {
            interval && clearInterval(interval.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const checkEventEnded = (duration, nextOccurence,activeTime) => {
        try {
            let videoDurationMins = duration;
            let videoDurationSecs = duration * 60;
             if (videoDurationSecs >= 60)
                 videoDurationSecs = (duration / 60).toString().split('.');
            let next_Occurence = nextOccurence+"T"+activeTime
            let videoStreamingTime = new Date(next_Occurence);
            videoStreamingTime.setMinutes(videoStreamingTime.getMinutes() + videoDurationMins);
            if (videoDurationSecs.length > 0) {
                let seconds = parseInt(videoDurationSecs[1].slice(0, 2))
                videoStreamingTime.setSeconds(videoStreamingTime.getSeconds() + seconds);
            }
            else {
                let seconds = parseInt(videoDurationSecs)
                videoStreamingTime.setSeconds(videoStreamingTime.getSeconds() + seconds);
            }
            let currentTime = new Date()
            if (currentTime > videoStreamingTime) {
                setReducerData(liveStreamPlayerDispatch,{liveVideoEnded : true })
                return true
            }
            return false
        }
        catch (error) {
            return false
        }
    }

    useEffect(() => {
        if (!loading && !isSubscriptionLoading && liveVideoEnded && hasPlayerAccess) {
            liveEventEnded();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, isSubscriptionLoading, liveVideoEnded,hasPlayerAccess])

    useEffect(() => {

        if (recordedClasses) {
        //just to add modified description   
         if(props.location?.state?.cardDetails){
            setReducerData(liveStreamPlayerDispatch, {
                cardDetails: ({ ...cardDetails,description: recordedClasses.eventDetail.description})
            })} 
            if (!recordedClasses.eventDetail.isStreaming) {
                if (!recordedClasses.eventDetail.isRecurring) {
                    // if (recordedClasses.eventDetail.live.scheduled_Start_Time < new Date()) {
                    hasPlayerAccess && liveEventEnded();
                    navigate( Routes.connectLiveSchedule,
                        { state: { clubClasses: props.clubClasses } } );
                    // }
                } else {
                    if (eventStatus === LiveStreamStatusConstants.STREAMING) {
                        if (new Date(cardDetails.nextOccurence) <= new Date(recordedClasses.eventDetail.next_Occurrence_Time)) {
                            setReducerData(liveStreamPlayerDispatch, {
                                cardDetails: ({ ...cardDetails,description: recordedClasses.eventDetail.description,nextOccurence: recordedClasses.eventDetail.next_Occurrence_Time }),
                                eventStatus: (LiveStreamStatusConstants.UNAVAILABLE)
                            })
                        }
                    } else if (fromDeepLink) {
                         setReducerData(liveStreamPlayerDispatch, {
                            cardDetails: ({
                                activeTime: recordedClasses.eventDetail.startTime,
                                description: recordedClasses.eventDetail.description,
                                duration:recordedClasses.eventDetail.eventDuration,
                                eventStatus: recordedClasses.eventDetail.isStreaming,
                                id: recordedClasses.event_id,
                                nextOccurence: recordedClasses.eventDetail.eventOccurrenceDate,
                                thumbnail: recordedClasses.eventDetail.imageUrl,
                                title: recordedClasses.eventDetail.title,
                                trailerLinkWeb: recordedClasses.liveStreamingLink,
                            }),
                            FLLStreamingVideoLink: (StreamingVideoLink)
                        })
                        if (!checkEventEnded(recordedClasses.eventDetail.eventDuration,recordedClasses.eventDetail.eventOccurrenceDate,recordedClasses.eventDetail.startTime)) {
                            const sDate = new Date(recordedClasses.eventDetail.eventOccurrenceDate + "T"+ recordedClasses.eventDetail.startTime);
                            if (
                                new Date().getTime() > new Date(sDate).getTime() &&
                                new Date().getTime() < new Date(sDate).getTime() +
                                recordedClasses.eventDetail.eventDuration * 60 * 1000) {
                                setReducerData(liveStreamPlayerDispatch,{eventStatus : (LiveStreamStatusConstants.STREAMING)})

                            }
                        }
                    }

                }
            }
            if (pageShared) {
                setReducerData(liveStreamPlayerDispatch, {
                    cardDetails: ({
                        activeTime: recordedClasses.eventDetail.startTime,
                        description: recordedClasses.eventDetail.description,
                        eventStatus: recordedClasses.eventDetail.status,
                        id: eventId,
                        isClubConnect: true,
                        nextOccurence: recordedClasses.eventDetail.eventOccurrenceDate,
                        thumbnail: recordedClasses.eventDetail.imageUrl,
                        title: recordedClasses.eventDetail.name ?? recordedClasses.eventDetail.title,
                        trailerLinkWeb: recordedClasses.trailerLinkWeb,
                    }),
                    eventStatus: (recordedClasses.eventDetail.status)
                })
            }
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordedClasses])

    useEffect(() => {
        if (recordedClasses && videoStarted && isFLLEvent) {
            liveStreamStarted(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordedClasses,videoStarted])

    useEffect(() => {
        if (StreamingVideoLink) {
            if (StreamingVideoLink.includes("https")) {
                interval && clearInterval(interval.current)
                if (!isFLLEvent)  setReducerData(liveStreamPlayerDispatch,{eventStatus : (LiveStreamStatusConstants.STREAMING)  }); //setEventStatus(LiveStreamStatusConstants.STREAMING);

            } else {
                if (interval.current) {
                    setReducerData(liveStreamPlayerDispatch,{eventStatus : (LiveStreamStatusConstants.AWAITING)})

                } else {
                    setReducerData(liveStreamPlayerDispatch,{eventStatus : (LiveStreamStatusConstants.UNAVAILABLE)})

                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [StreamingVideoLink])

    useEffect(() => {
        if (!isSubscriptionLoading&&isSubscriptionLoading!==null) {
            if (hasPlayerAccess === false) {
                if (config.showUpgradeSubscriptionPopUp) {
                    setReducerData(liveStreamPlayerDispatch,{showPopUPforSSO : true ,eventStatus : (LiveStreamStatusConstants.UNAVAILABLE)})

                } else {
                    if (isAuthenticated)
                        navigate("/subscribe" );
                }
            } else {
                setReducerData(liveStreamPlayerDispatch,{showPopUPforSSO : false })
            }
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubscriptionLoading])

    function checkForStreamRecursively(nextOccurence) {
        if (nextOccurence) {
            var startTime = new Date().getTime();
            setReducerData(liveStreamPlayerDispatch,{eventStatus : (LiveStreamStatusConstants.AWAITING)})

            interval.current = setInterval(() => {
                if (new Date().getTime() - startTime > 60 * 10 * 1000) {
                    clearInterval(interval.current);
                    // setEventStatus(LiveStreamStatusConstants.UNAVAILABLE)
                    setReducerData(liveStreamPlayerDispatch, { showAwaitError : true})
                    return;
                }
                fetchStreamingLink(eventId)
            }, 5000)
        }
    }

    /* function onDurationChanged(time) {
        logger("Duration Event fired")
        logger(time);
        if (time == 0) {
            logger("Duration setting to 1")
            setReducerData(liveStreamPlayerDispatch,{duration : 1})

        }
        else {
            setReducerData(liveStreamPlayerDispatch,{duration : time})

            if (!videoEnded)
                onVideoEnded()
        }
    } */

    function onVideoEnded(isVideoEnd = false) {
        const userId = localStorage.getItem("userId");
        clearInterval(curVideoTime);
        curVideoTime = null;
         const videoDetails = {
            ...cardDetails,
            duration: (cardDetails?.duration * 60),
            providerId: recordedClasses?.eventDetail?.providerId,
            providerName:recordedClasses?.eventDetail?.providerName
        }
        if (starter > 0) logOnDemandTracking(videoDetails, userId, starter, cardDetails?.isClubConnect ?? false);
        if ((starter > 60 || isVideoEnd) && recordedClasses?.eventDetail?.eventType !== "true-live"){
          //  postWorkoutResult(cardDetails,9,starter)
        }
        starter = 0;
        if(recordedClasses.eventDetail?.eventType === "true-live" && liveVideoEnded === false){
            setReducerData(liveStreamPlayerDispatch,{liveVideoEnded : true})
        }
        if(videoEnded === false) setReducerData(liveStreamPlayerDispatch,{videoEnded : true})
        if(showImageLayoutOnly === false) setReducerData(liveStreamPlayerDispatch,{showImageLayoutOnly : true})
    }
    const getSeekingTime = ()=>{
        if (isFLLEvent && eventStatus === "streaming") {
            if(fromDeepLink){
                if (recordedClasses.eventDetail?.startTime && recordedClasses.eventDetail?.eventOccurrenceDate) {
                    return new Date().getTime() - new Date(recordedClasses.eventDetail.eventOccurrenceDate+'T'+recordedClasses.eventDetail.startTime).getTime()
                }
                else return false
            }
            else{
                if (props.location.state.cardDetails.activeTime) {
                    return new Date().getTime() - new Date(props.location.state.cardDetails.nextOccurence+"T"+props.location.state.cardDetails.activeTime).getTime()
                }
                else return false
            }
        }
        else return false
    }
    function startVideo(video,jPlayer) {
        player=jPlayer;
        if (video != null && hasPlayerAccess && player!==null) {
                player.once("play", () => {
                    if (isFLLEvent)setReducerData(liveStreamPlayerDispatch,{videoStarted : true})
                        setReducerData(liveStreamPlayerDispatch,{bindedPlayer : player})
                    if (videoInitialised === false) {
                        setReducerData(liveStreamPlayerDispatch,{videoInitialised : true})
                        curVideoTime = setInterval(()=>starter++, 1000);
                        // setTimeout(() => {
                        //     const {dvrLive,...rest} = player.getConfig();
                        //     console.log(dvrLive,rest)
                        //     if(dvrLive !== true){
                        //         player.pause();
                        //         videoCompleteHandelMsg(player)
                        //         // onVideoEnded(true);
                        //     }
                        // }, 2000);

                    }
                    const seekTime = getSeekingTime();
                    player.__proto__.wexer = {updateTime : true};
                    if(!isTrueLive() && seekTime && player.wexer)
                    {
                        //player.__proto__.wexer = {updateTime : true};
                        player.seek(seekTime / 1000);
                    }
                });
                player.once("remove", e => {
                    setReducerData(liveStreamPlayerDispatch,{showImageLayoutOnly : true})
                    sendLiveStreamDurationToLocalytics(player.getCurrentTime(),recordedClasses)
                })
                player.on("error", (e)=> {
                    if(e?.code === 230001 && navigator.onLine){
                        videoCompleteHandelMsg(player);
                        handelErrorMsg(player)
                    }
                })
                player.on("complete", e => {
                    console.log(e,player.getConfig(),"complete");
                    // e.stopImmediatePropagation()
                        logger('video is ended!');
                        videoCompleteHandelMsg(player);
                        // setImageLayOutStatus(true)
                })
        }
    }
    const handelErrorMsg = (jWplayer = bindedPlayer)=>{
        try {
            const videoErrEle = jWplayer.getContainer().querySelectorAll(".jw-error-msg");
            if(videoErrEle){
                videoErrEle[0].remove()
            }
        } catch (error) {

        }

    }
    const videoCompleteHandelMsg = (jWplayer = bindedPlayer)=>{
        if (document.fullscreenElement !== undefined && document.fullscreenElement !== null) {
            jWplayer.setAllowFullscreen(false);
        }
        setReducerData(liveStreamPlayerDispatch,{duration : jWplayer.getCurrentTime() || starter})
        if (!videoEnded) onVideoEnded(true)
    }
    const closehandlerCallback = () => {
        //Rajneesh raghav, Handling Close button
        if (eventStatus === LiveStreamStatusConstants.STREAMING) {
            logger(bindedPlayer);
            if (bindedPlayer) {
                bindedPlayer.dispose()
                // setPlayer(null);
                //setVideoInitialisation(null)
            }
        }
       else {
        if (eventStatus !== LiveStreamStatusConstants.STREAMING) {
          // check if event is live or recorded
          if (recordedClasses?.type === "true-live") {
            setReducerData(liveStreamPlayerDispatch, {
              FLLStreamingVideoLink: StreamingVideoLink,
              eventStatus: LiveStreamStatusConstants.STREAMING,
            });
          }
        }
      }
    };
    const closehandler = () => {
        if (eventStatus === LiveStreamStatusConstants.STREAMING) {
            if (showLeaveModal === true) {
                setReducerData(liveStreamPlayerDispatch,{showLeaveModal : false})

            } else {
                setReducerData(liveStreamPlayerDispatch,{showLeaveModal : true})
            }
          }
          // check if event is recorded and recorded time is less than current time
          else if (isTimeStart()) {
            if (isFLLEvent && (FLLStreamingVideoLink === null || FLLStreamingVideoLink === undefined)) {
              setReducerData(liveStreamPlayerDispatch, {
                FLLStreamingVideoLink: StreamingVideoLink,
              });
            }
        }
        else {
            if (fromDeepLink)
                navigate(Routes.homePage);
            else
                navigate(-1);
        }
    };
    const isTimeStart = ()=>{
        return isSLClassStarted(recordedClasses?.eventDetail?.eventOccurrenceDate+"T"+recordedClasses?.eventDetail?.startTime)
    }
    const isCountingShow = ()=>{
        const sDate = new Date(recordedClasses?.eventDetail?.eventOccurrenceDate+"T"+recordedClasses?.eventDetail?.startTime);
        const cDate = new Date();
        const diff = sDate - cDate;
        const dayLengthInMS = 24 * 60 * 60 * 1000;
        return diff < dayLengthInMS&&diff>0
    }
    const fetchRecords = (showLoading)=>{
        try{
            fetchStreamingLink(props.location.state.cardDetails.id)
        }
        catch(e){
            fetchStreamingLink(getEventId())
        }
    }
    const getEventId = ()=>{
        var path = window.location.pathname;
        var pathItems = path.split("/");
        if(pathItems.length>2&&pathItems[1]==="live"){
            return pathItems[2]
        }
        else if(pathItems.length>2&&pathItems[2]==="live"){
            return pathItems[3]
        }
    }
    const getRecordsRecursivly = useCallback(() => {
        intervalForStreaming.current = setInterval(() => fetchRecords(false), 5000);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
    ,[])
    useEffect(() => {
        if(!loading && !isEmpty(recordedClasses)){
            if(isStreamingLinkEmpty){
                // user checks fromDeepLink
                if( !isCountingShow() && fromDeepLink && eventStatus === null){
                    updateEventStatus();
                }
                if(props?.location?.state?.cardDetails?.eventStatus === "streaming"){
                    setTimeout(()=>{
                        if(!isCountingShow() && eventStatus === null&& liveVideoEnded === false){
                            setReducerData(liveStreamPlayerDispatch,{liveVideoEnded : true })
                            }
                    },2000);
                }
                if( eventStatus === LiveStreamStatusConstants.AWAITING){
                    getRecordsRecursivly();
                }
            }
            else{
                if(eventStatus !== LiveStreamStatusConstants.STREAMING){
                    // check if event is live or recorded
                    if(recordedClasses?.eventDetail?.eventType === "true-live"){
                        setReducerData(liveStreamPlayerDispatch,{FLLStreamingVideoLink : StreamingVideoLink, eventStatus: LiveStreamStatusConstants.STREAMING })
                    }

                    // check if event is recorded and recorded time is less than current time
                    else if(isTimeStart()){
                        if(isFLLEvent && (FLLStreamingVideoLink === null || FLLStreamingVideoLink === undefined)){
                            setReducerData(liveStreamPlayerDispatch,{FLLStreamingVideoLink : StreamingVideoLink})
                        }
                        setReducerData(liveStreamPlayerDispatch,{eventStatus: LiveStreamStatusConstants.STREAMING })
                    }
                }
            }
        }
        return()=>{
            intervalForStreaming && clearInterval(intervalForStreaming.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventStatus,isStreamingLinkEmpty,recordedClasses,loading,isCountingShow,fromDeepLink,LiveStreamStatusConstants.AWAITING])
    const classStartsIn = (rawDate,startTime) => {
        const stringDate = `${rawDate}T${startTime}`
        const sDate = new Date(stringDate);
        const cDate = new Date();
        const diff = sDate - cDate;
        const dayLengthInMS = 24 * 60 * 60 * 1000
        if (diff < dayLengthInMS && diff > 0) {
            return (
                <Timer checkpoints={[
                    {
                        time: 0,
                        callback: () => {
                            if (isFLLEvent) {
                                setReducerData(liveStreamPlayerDispatch,{eventStatus : (LiveStreamStatusConstants.AWAITING)})

                            } else {
                                checkForStreamRecursively(cardDetails.nextOccurence)
                            }
                        }
                    },
                ]}
                    formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`} initialTime={diff || 0} direction="backward">
                    <Timer.Hours />{':'}<Timer.Minutes />{':'}<Timer.Seconds />
                </Timer>)
        }
    }

    const handleMemberShipDialog = () => {
        if (config.UpgradeRedirect != null) {
            window.open(config.UpgradeRedirect, '_blank')
            navigate(-1);
        }
        else {
            setReducerData(liveStreamPlayerDispatch,{showPopUPforSSO : false })

        }
      }

      useEffect(() => {
        if (cardDetails && dynamicShortLink) {
          setDynamicLinkDetails({
            shareUrl: dynamicShortLink,
            title: cardDetails.title,
          });
          setShowHideShareView(true);
        }
      }, [cardDetails, dynamicShortLink]);

      const share = () => {
        if (dynamicShortLink) {
          setDynamicLinkDetails({
            shareUrl: dynamicShortLink,
            title: cardDetails.title,
          });
          setShowHideShareView(true);
        } else {
          let classType = ClassType.LIVE_SIMULATED_SVOD;
          let classDetails = {
            classId: cardDetails.id,
            title: cardDetails.title,
            description: cardDetails.description,
            imgLink: cardDetails.thumbnail,
          };
          const configJson = require("../../../src/assets/config.json");
          getShortDeepLink(classType, classDetails, configJson);
        }
      };

    const isTrueLive=()=>recordedClasses?.eventDetail?.eventType ==="true-live";
    return (
        <Fragment>
            {loading || isSubscriptionLoading ? <PlayerShimmer player='LSPlayer'/>  : cardDetails && <div className="page-container">
                <CustomDialog
                    open={showPopUPforSSO}
                    title={Translate({ id: "Subscription.UpgradeMembership" })}
                    heading={Translate({ id: "Subscription.UpgradeMessage" })}
                >
                    <div className="text-center padding16">
                        <Button size="small" type="submit" color="secondary"
                            className="button btn-default" variant="contained"
                            onClick={handleMemberShipDialog}
                            style={{ marginTop: "60px" }}
                        >
                            <Typography variant="h5">
                                {Translate({ id: "Subscription.Ok" })}
                            </Typography>
                        </Button>
                    </div>
                </CustomDialog>
                <div container justify="flex-start" direction="row">
                    <div className="d-flex">
                        <div className='vido-metadata-row shareLiveViewRowWidth'>
                            <p className="customh1 m-t-xs-5">
                            {Translate({ id: `${cardDetails.title}` })}
                            </p>
                        </div>
                    </div>
                    {config?.shareEnabled &&
                            <div className="meta-desc share-btn-align" >
                                <span onClick={share}>
                                <ShareIcon                                 
                                id="share-icon-2"
                                color={config.primaryColor} 
                                />
                                </span>
                            </div>}
                    {/* <Grid item variant="contained" lg={12} md={12} sm={12} xs={12}> */}
                    <div className="clear video-wrapper" id='video-wrapper'>
                        {eventStatus === LiveStreamStatusConstants.STREAMING ?
                            <div className='livePlayer player-border'>
                                <PureVideoWrapper isVideoInit={videoInitialised} eventStatus={eventStatus}>
                                    <div className='livePlayer livePlayer-mt player-border video-js'>
                                        <VideoPlayer
                                            startVideo={startVideo}
                                            hideControls={!isTrueLive()}
                                            isLive
                                            autostart
                                            file={isFLLEvent ? FLLStreamingVideoLink : StreamingVideoLink}
                                            image={cardDetails.thumbnail}
                                            eventId={eventId}
                                            navigate={navigate}
                                            location={props.location}
                                        />
                                    </div>
                                </PureVideoWrapper>
                                <div className="livePlayer-info2">
                                        <div className="container-info">
                                            <img
                                                alt="close"
                                                className="CloseButton livePlayerClose btn-default hidden-xs"
                                                src={require("../../CommonImages/close.png")}
                                                onClick={closehandler}
                                            />
                                            <div className="btn-container" onClick={closehandler}>
                                            <CloseGreen cls={"closeButtonMobile customClosePlayer modalClose makeTextClickable"} color={config.primaryColor} />
                                            </div>
                                            {/* <Typography variant="body1">
                                                <span className="live-label">{Translate({ id: "liveStream.Live" })}</span>
                                            </Typography> */}
                                        </div>
                                </div>
                                <div  className={`livePlayer ClassPlayer live-leave ${(!showLeaveModal && !showImageLayoutOnly ) && "remove-pointer-events"}`}>
                                {
                            showLeaveModal &&
                            <Modal currentTime={duration}
                                classDetails={cardDetails}
                                history={props.history}
                                callback={closehandlerCallback}
                                class="liveStram-video-paused-infobox"
                                fromDeepLink={fromDeepLink}
                            />
                        }
                         {showImageLayoutOnly &&
                            <React.Fragment>
                                <ClassCompleted navigate={navigate} cardDetails={cardDetails} duration={duration} />
                            </React.Fragment>

                        } </div>
                            </div>
                            :
                            <div className="livePlayer livePlayer-mt video-js player-border" style={{ zIndex: 0 }}>
                                <img src={cardDetails.thumbnail} style={{ width: "100%", height: "100%" }} className="player-border" alt='cardThumbnail'/>
                                <div className="livePlayer-info noBorderRadiusButton livePlayer-loader">
                                    <div className="container-info">
                                    {eventStatus !== LiveStreamStatusConstants.AWAITING &&
                                        <React.Fragment>
                                            {videoInitialised && <img
                                                className="CloseButton livePlayerClose btn-default hidden-xs"
                                                src={require("../../CommonImages/close.png")}
                                                onClick={closehandler}
                                                alt='closeButton'
                                            /> }
                                            {videoInitialised &&  <div className="btn-container" onClick={closehandler}>
                                                    <CloseGreen cls={"btn-container"} color={config.primaryColor} />
                                            </div> }
                                            {/* {showLeaveModal &&
                                                <Modal currentTime={duration}
                                                    classDetails={cardDetails}
                                                    history={props.history}
                                                    callback={closehandlerCallback}
                                                    class="liveStram-video-paused-infobox"
                                                    fromDeepLink={fromDeepLink}
                                                />
                                            }
                                            {showImageLayoutOnly &&
                                                <React.Fragment>
                                                    <ClassCompleted history={props.history} cardDetails={cardDetails} duration={duration} />
                                                </React.Fragment>
                                            } */}
                                        </React.Fragment>}
                                    <Typography variant="body1">
                                      {(recordedClasses?.eventDetail?.eventType === "true-live" || recordedClasses?.eventDetail?.eventType === "simulated-live") && <span className="live-label-player">
                                        {Translate({ id: "liveStream.Live" })}</span>}

                                        {eventStatus !== LiveStreamStatusConstants.AWAITING &&
                                            eventStatus !== LiveStreamStatusConstants.STREAMING &&
                                            <span className="timeDayText">
                                                {getNextOccurenceDate(cardDetails.eventStatus, cardDetails.activeTime, cardDetails.nextOccurence)}
                                                {" "}{Translate({ id: "liveStream.at" })}{" "}
                                                {getTimetoStart(cardDetails.nextOccurence,cardDetails.activeTime)}
                                            </span>
                                        }
                                    </Typography>
                                    {eventStatus === LiveStreamStatusConstants.AWAITING &&
                                        <React.Fragment>
                                            {!showAwaitError && <Spinner size={20} thickness={3} />}
                                            <div className="loader-caption">{showAwaitError ? Translate({ id: "liveStream.AwaitingStreamError" }) :
                                                Translate({ id: "liveStream.AwaitingStream" })}</div>
                                        </React.Fragment>}
                                    {eventStatus !== LiveStreamStatusConstants.AWAITING &&
                                        <p className="timer">
                                            {classStartsIn(cardDetails.nextOccurence,cardDetails.activeTime)}
                                        </p>
                                    }
                                    </div>
                                </div>
                            </div>
                        }
                        {/* {
                            showLeaveModal &&
                            <Modal currentTime={duration}
                                classDetails={cardDetails}
                                history={props.history}
                                callback={closehandlerCallback}
                                class="liveStram-video-paused-infobox"
                                fromDeepLink={fromDeepLink}
                            />
                        }
                         {showImageLayoutOnly &&
                            <React.Fragment>
                                <ClassCompleted history={props.history} cardDetails={cardDetails} duration={duration} />
                            </React.Fragment>

                        }   */}
                    </div>
                    <div className='new-video-metadata'>
                        <div className="clear align-left">
                            <Typography variant="h6" className="text-gray rich-desc" style={{ whiteSpace: "pre-wrap" }}>
                            {cardDetails.description ? ReactHtmlParser(cardDetails.description) : null}
                            </Typography>
                        </div>
                    </div>
                    {(recordedClasses && (cardDetails?.type === "true-live" || cardDetails?.type === "simulated-live") && eventData?.length) ? <Fragment>
                            <div className="clear m-t-40  m-t-xs-15 align-left m-b-5 m-b-xs-10 rec-class-title">
                                <Typography variant="body1">
                                    {cardDetails.title}{": "}
                                    {Translate({ id: "liveStream.RecordedClasses" })}
                                </Typography>
                            </div>
                        <div container justify="flex-start" spacing={4} className="list">
                            {!eventData ?
                                <Grid item>
                                    <Typography className="text-gray align-left m-t-xs-15" variant="h6" >
                                        {Translate({ id: "liveStream.NoRecordedResult" })}
                                    </Typography>
                                </Grid> :
                                eventData && Array.isArray(eventData) ? eventData.filter(
                                    (item) => item.videoID).map(
                                        (eventData) =>
                                            <div key={eventData.videoID}>
                                                <ClubCardNew clubData={eventData} liveStream={true} isMoreClassSuggetion={true} eventId={eventId}></ClubCardNew>
                                            </div>
                                    ) :
                                    <div key={recordedClasses.eventData.videoID}>
                                        <ClubCardNew clubData={recordedClasses.eventData} liveStream={true} isMoreClassSuggetion={true} eventId={recordedClasses.eventId}></ClubCardNew>
                                    </div>
                            }

                        </div>
                    </Fragment> : null}
                </div>
            </div>}
            {showHideShareView && (
        <ShareView
          show={showHideShareView}
          dynamicLinkDetails={dynamicLinkDetails}
          closeDialog={() => setShowHideShareView(false)}
        />
      )}
        </Fragment>
    )
}

export default LiveStreamPlayer;

