import React, { useCallback, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
const config = require('../../assets/config.json')

const Spinner = (props)=> {
    const {fetchTenantConfig,loadPoKeys,areCards} = props
    const getInitials = useCallback(()=>{
        fetchTenantConfig();
        setTimeout(() => {
            loadPoKeys()
        }, 5000);
    },[fetchTenantConfig,loadPoKeys])
    useEffect(()=>{getInitials()},[getInitials]);
    return (
        <div style={{ textAlign: "center" }} className="classes-spinner">
            {!areCards && ( 
                <CircularProgress
                    label="lable"
                    style={{color:config.primaryColor}}
                    size={70}
                    // className={classes.progress}
                    thickness={5}
                    //color="red"
                />
            )}
        </div>
    );
}

export default Spinner;
