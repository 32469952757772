import { useCallback, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getItemsOfPlayList, PlayListInfoModal, removeClassFromPlayList, removePlayList, setItemsOfPlayList, updatePlayList } from "../store/actions/playList";
import { isEmpty,reject,find,includes } from "lodash";
import { PlayList,CLASS_LOG_TIMEOUT } from "../utils/constants";
import { useNavigate } from "react-router-dom";
import Routes from "../components/Router/Routes";

const usePlayListInfo = (playlistId) => {

    const playListInfo = useSelector(state => state.playList?.info,shallowEqual)
    const tenantId = useSelector(state => state.client?.data?.tenantID ?? '')
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handelModal =(_,key,selectedRow)=>dispatch(PlayListInfoModal(_,key,selectedRow));
    const fetchPlayListInfo =()=>dispatch(getItemsOfPlayList(tenantId,playlistId));
    const setPlayListInfo =(list)=>dispatch(setItemsOfPlayList(tenantId,playlistId,list));
    const updatePlayListTitle =(title,description)=>dispatch(updatePlayList(tenantId,title,description,playlistId));
    const deletePlayList =()=>dispatch(removePlayList(tenantId,playlistId));
    const deleteClass =()=>dispatch(removeClassFromPlayList(tenantId,playlistId));
    // WHILE LANDING ON PAGE
    useEffect(()=>{
        fetchPlayListInfo();
    },[])
    // SHOW NOTIFICATION IF ANY ACTION IS DONE LIKE(ERROR,INSERT,UPDATE)
    const handelNotification = ()=>{
        if(includes([   PlayList.msg.created,
                        PlayList.msg.updated,
                        PlayList.msg.deleted,
                        PlayList.msg.classDeleted
                    ],playListInfo?.msg)) return true;
        if(playListInfo?.error && !isEmpty(playListInfo?.msg)) return true;
        return false;
    }
    const showNotification = handelNotification();
    // SHOW DEFAULT PAGE
    const showList = !playListInfo?.list?.length && playListInfo?.msg === PlayList.msg.get ? false : true
    // SHOW NOTIFICATION SEVERITY
    const severity = playListInfo.error ? PlayList.notification.severity.error :PlayList.notification.severity.success;
    const backToPlaylist = ()=> navigate(Routes.userPlayList)
    const movePlayListItem = useCallback((hoverIndex, playListItemId) => {
        const infoList = reject(playListInfo.list,{playListItemId})
        const selectedRow = find(playListInfo.list,{playListItemId})
        infoList.splice(hoverIndex,0,selectedRow);
        setPlayListInfo(infoList);
    }, [playListInfo?.list])
    playListInfo?.isPlayListRemoved && setTimeout(backToPlaylist, CLASS_LOG_TIMEOUT * 100);;
    return {playListInfo,tenantId,severity,showNotification,showList,movePlayListItem,updatePlayListTitle,handelModal,modal:playListInfo?.modal,modalDelete:playListInfo?.modalDelete,deletePlayList,deleteClass}
}

export  {usePlayListInfo}