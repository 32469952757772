import Workout from "../components/PlayerAndCarousel/Workout/Workout"
import { connect } from "react-redux";
import {injectIntl} from 'react-intl';
import {
    setClassFavourite, setClassFavouriteOnLocalColletion,
    fetchIntensity,
    savePrevPlayer,
  } from "../store/actions/ondemand";

const mapStateToProps = state => {
    return {
      prevPlayer: state.onDemand.prevPlayer
  
    };
  };
  const mapDispatchToProps = dispatch => {
    return {
      savingPrevPlayer: player => dispatch(savePrevPlayer(player)),
      onsetClassFavourite: (selectedClassTag, userId, isSettingFavourite) =>
        dispatch(setClassFavourite(selectedClassTag, userId, isSettingFavourite)),
      onLoadIntensity: () => dispatch(fetchIntensity()),
      onsetClassFavouriteLocal: (selectedCollection, classId, isSettingFavourite) =>
        dispatch(setClassFavouriteOnLocalColletion(selectedCollection, classId, isSettingFavourite))
    };
  };


  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(injectIntl(Workout));