import React, {  Component } from "react";
import "./Workout.css";
import withStyles from "@material-ui/core/styles/withStyles";
import { Translate } from "utils/Translate"
import logger from "utils/logger"
import { getCardsDate } from "utils/LivestreamTime"
import NewButton from "./NewButton";
import { differenceInCalendarDays, parse } from 'date-fns'
import { isMobileOrTab } from "utils/constants";
import moment from "moment";
import {getPosterSize} from "utils/utilityfunctions"
var config = require("assets/config.json");
const style = {
  pausedButton: {
    width: ".8em",
    height: ".8em",
    marginTop: "-1px",
  },

  newButton: {
    maxWidth: "30px !important",
    minWidth: "29px",
    paddingLeft: "0px",
    paddingRight: "0px",
    fontSize: "8px",
    fontWeight: "500",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "2",
    "& span": {
      paddingLeft: "4px",
      justifyContent: "flex-end",
    },
  },
};

class Workout extends Component {
  constructor(props) {
    super(props);
    this.token = localStorage.getItem("token");
    // this.pauseVideo = this.pauseVideo;
    // this.startVideo = this.startVideo;
    // this.player;
    this.myRef = React.createRef();
    this.playerInit = null;
    this.state = {
      isClassFav: this.props.isFav || false,
      selectedItem: "",
      isPlaying: false,
      isClassNew: false,
      isPlayerAvailable: false,
      prevPlayer: null,
      showVideo:false
    };
  }

  componentWillUnmount() {
    if (this.player) {
      this.player=undefined;
    }
    if(this.myRef&&this.myRef.current){
      this.myRef.current.src=""
    }
  }
  // startTrail = video => {
  //   this.player = new Plyr(video);
  //   this.player.on("pause", e => {
  //     this.setState({ isPlaying: false });
  //   });
  // };

  componentDidMount() {
    this.isNewCardFn(this.props.displayDate);
  }

  // getClass=()=>{
  //   dispatch
  //   let reqCollection= this.state.collection.filter((particularCollection)=> particularCollection.collectionName === this.props.collectionName);
  //   let reqClass=reqCollection[0].collectionItems.filter(particularClass=>particularClass.tag=== this.props.id);
  //   return reqClass;
  // }

  // startVideo = () => {
  //   if (this.token != null) {
  //     if (!this.state.isPlaying) {
  //       if (this.props.prevPlayer != null) {
  //         this.props.prevPlayer.pause();
  //       } else {
  //         if (!this.props.isSearchPageCard) {

  //         }
  //       }
  //       if (this.state.isPlayerAvailable == false) {
  //         const node = this.myRef.current;
  //         this.startTrail(node);
  //         this.setState({ isPlayerAvailable: true });
  //       }

  //       this.player.play();
  //       this.setState({ isPlaying: true }, () => {
  //         this.props.savingPrevPlayer(this.player);
  //       });
  //     } else if (this.state.isPlaying) {
  //       if (this.player != undefined) {
  //         this.player.pause();

  //       }
  //     }
  //   } else {
  //     this.props.history.push({
  //       pathname: "/signin",
  //     });
  //   }
  // };
  handelRightClick = (player)=>{
    const videoEle = player.getContainer();
    videoEle.addEventListener("contextmenu",()=>{
      try{
        const menuEle = videoEle.querySelectorAll("ul.jw-rightclick-list.jw-reset")[0];
        menuEle.style.display="none";
        return false;
      }catch(e){
        return false;
      }
    });
  }

  isTrailer = ()=> {
    if (this.props.trailerLinkMobile) {
      return true
    }
    return this.props.trailerLinkWeb ? true : false      
  }

  startVideo = (video) => {
    this.playerInit = setTimeout(() => {
      const JP = window.jwplayer;
      const uri = video?.currentSrc || video?.children[0]?.src;
      const isValid = navigator?.onLine && isMobileOrTab() === false;
      if(uri && isValid){
        if(this.isM3u8(uri) && JP ){
          if(JP(video.id).setup)
          {
            this.setState({showVideo : true})
            this.player = JP(video.id).setup({
              file:uri,
              image:video.poster,
              controls:false,
              repeat: true,
              events:{
                "ready":()=>{
                  this.handelRightClick(this.player);
                  if(this.player.wexer) this.player.__proto__.wexer = undefined  // clearing custom data from jw player
                  const ele = this.player.getContainer();
                  const workoutPlayerEle =  ele.closest(".workoutPlayer");
                  const cotd2Ele =  ele.closest(".cotd2");
                  if(workoutPlayerEle){
                    workoutPlayerEle.classList.add("h-218")
                  }
                  if(cotd2Ele){
                    cotd2Ele.classList.add("w-256")
                  }
                  ele.classList.add("Trailer","thumbnail","video-js");
                },
                "click":() => {
                  this.props.clicked()
                },
                "remove":function(){
                  const parentDiv = document.getElementById(video.id).parentNode
                  if(this.playerInit !== null){
                    parentDiv.id.includes('cotd-card') && parentDiv.classList.add('cotd-overlay')
                    clearTimeout(this.playerInit);
                    this.playerInit=null;
                  }
                },
                "time":()=>{
                  // check video length ? 6 mins => start from 5 mins else start from 0 mins , play duration 30 secs
                  if(!this.isTrailer() && this.player){
                  const videoLength = this.player.getDuration()
                  const currentDuration = this.player.getCurrentTime()
                  //check for video's duration > 6 mins
                  if (videoLength > 360){
                    if(330>currentDuration > 0 && this.player.wexer){ //check current playing time, avoid re-rendering
                      this.player.__proto__.wexer = {...this.player.wexer,seek : false};
                    } else{
                      this.player.__proto__.wexer = {...this.player.wexer,seek : true};
                    }
                    if( !this.player?.wexer?.trailer && this.player?.wexer?.seek===true ) this.player.seek(300); //check jw Player data,start video from 5mins
                    this.player.__proto__.wexer = {...this.player.wexer,trailer : true};
                    if (currentDuration > 330) { //check current playing time > 5mins 30 sec, reset the trailer
                    this.player.__proto__.wexer = {...this.player.wexer,trailer : false};
                    }
                  }
                   else if(currentDuration > 30){
                    this.player.seek(0);
                  }
                }
              },
              "beforePlay":function(){
                const currentPlayer = this.getContainer();
                const videos = document.querySelectorAll("div.jwplayer")
                videos.forEach((video)=>{
                if(video.id !== currentPlayer.id) JP(video.id).remove()
                 })
              },
                "play":function(){
                  const ele = this.getContainer();
                  const parentDiv = ele.parentNode
                  parentDiv.id.includes('cotd-card') && parentDiv.classList.remove("cotd-overlay")
                  ele.addEventListener("mouseleave",()=>{
                    // ele.querySelector("video").load();
                    this.remove();
                  })
                },
                "error":function(){
                  try {
                    const ele = this.getContainer();
                    const videoErrEle = ele.querySelectorAll(".jw-error-msg");
                    if(ele){
                        ele.querySelector("video").load();
                        this.remove();
                        videoErrEle[0].remove()
                    }
                    } catch (error) {
                      console.log(error.name)
                      console.log(error.message)
                    }
                }
              }
            });
          }
        }
        else{
              var playPromise = video.play();
              this.setState({showVideo : true})
              if (playPromise !== undefined) {
                const parentDiv = video.parentNode
                parentDiv.id.includes('cotd-card') && parentDiv.classList.remove("cotd-overlay")
                playPromise.then(_ => {
                  // Automatic playback started!
                  // Show playing UI.
                  logger("video started")
                })
                  .catch(error => {
                    // Auto-play was prevented
                    // Show paused UI.
                    logger("video Downloading")
                  });
              }
        }
      }
    }, 1000);
  }
  favVideo = () => {
    if (this.props.favCallback) {
      this.props.favCallback();
    }
    logger(this.props.collectionName)
    var userId = localStorage.getItem("userId");
    logger("id", this.props.id)
    this.props.onsetClassFavouriteLocal(this.props.collectionName, this.props.id, true)
    this.props.onsetClassFavourite(this.props.id, userId, true);
    this.setState({ isClassFav: true });
  };
  unfavVideo = () => {
    if (this.props.favCallback) {
      this.props.favCallback();
    }
    this.setState({ isClassFav: false });
    var userId = localStorage.getItem("userId");
    this.props.onsetClassFavouriteLocal(this.props.collectionName, this.props.id, false)
    this.props.onsetClassFavourite(this.props.id, userId, false);
  };

  isNewCardFn = (creationDate) => {
    let date = parse(creationDate);
    let difference = differenceInCalendarDays(new Date(), date);
    if (difference < config.numberOfDaysToShowNewOnWorkoutCard) {
      this.setState({
        isClassNew: true
      })
    }
  }

  isM3u8 =(str) =>{
    try {
      return str.match(/m3u8/ig)
    } catch (error) {
      return false;
    }
  }
  getMimeType(uri){
    try {
    return this.isM3u8(uri) ? "application/x-mpegURL" :  "video/mp4";
    } catch (error) {
      console.log("getMimeType",error.message,uri,error.name)
      return "video/mp4"
    }
  }
   cotdDate = (date)=>{
    try{
    return moment(date).format("dddd DD MMMM")
    } catch(e){
    return moment().format("dddd DD MMMM")
    }
 }

 getTrailerURL = () => {  
    if (this.props.trailerLinkMobile) {
      return this.props.trailerLinkMobile
    }
    if (this.props.trailerLinkWeb) {
      return this.props.trailerLinkWeb
    }
    return null
 }

  render() {
    const uri = this.getTrailerURL()
    const cardType = this.props.cardType
    const collectionType = this.props.collectionType
    return (
            <div key={this.props.id}>
                    {collectionType !=="channel" && <div><div className={`${cardType} makeTextClickable`} onClick={this.props.clicked}
                    onMouseOut={e => {
                      if(this.playerInit !== null){
                      this.setState({showVideo : false})
                      clearTimeout(this.playerInit);
                      this.playerInit=null;
                      e.target.load();
                      if(this.player){
                        this.player.remove()
                      }
                    }
                  }}
                    > <div className={`cardPos comman`} > 
                          <div id={cardType+this.props.id} className={`imageHolder ${cardType !== 'cotd-card' ? collectionType !== 'featured' ? "full-overlay":"noOverlay" :"cotd-overlay"}`}>
                            <video
                                style={{
                                  backgroundColor: this.state.isPlaying ? "#000" : "transparent"
                                }}
                                id = {`video-${this.props.id}`}
                                preload="none"
                                loop
                                playsInline
                                ref={this.myRef}
                                onMouseOver={(e) => {
                                  !this.props.isMoreClassSuggetion && this.startVideo(e.target)
                                }}
                                className={`Trailer thumbnail`}
                                poster={this.props.thumbnail ?
                                    `${this.props.thumbnail}?width=${getPosterSize(cardType).cardWidth}` : ""
                                  }
                                onMouseOut={e => {
                                  if(this.playerInit !== null){
                                    const parentDiv = document.getElementById(`video-${this.props.id}`).parentNode
                                    parentDiv.id.includes('cotd-card') && parentDiv.classList.add('cotd-overlay')
                                    clearTimeout(this.playerInit);
                                    this.setState({showVideo : false})
                                    this.playerInit=null;
                                    e.target.load();
                                    if(this.player){
                                      this.player.remove()
                                    }
                                }
                              }}
                              data-testid={this.props.title}
                              >
                                <source
                                  src={uri}
                                  type={this.getMimeType(uri)}
                                />
                            </video>
                            {!this.state.showVideo && <div className="contentTop">
                                <div className="timeholder">
                                {cardType !== 'cotd-card' && this.state.isClassNew && <NewButton />}
                               {collectionType ==="live" && <span color="white" className="live-title m-b-8">{this.props?.title?.substring(0,20)}</span> }
                               {collectionType !=="featured" && <span color="white" className={`classname ${cardType === 'cotd-card' ? "cotd-date":"m-b-5 "}`}>{this.cotdDate(this.props.displayDate)}</span> }
                                </div>
                            </div>}
                          </div>
                      </div>
                    </div>
                {collectionType !=="live" && <div className="card-footer">
                    <div color="white" style={{color:'black'}} className={cardType === 'cotd-card' ? "time title-truncate cotd-time":"title-truncate time"}>{(this.props.title)}</div>
                        <div className="d-flex">
                            <span color="white" style={{color:'grey'}} spacing="xl" className="font-caption discIcon">{this.props.duration !== 0 ? `${this.props.duration}` : `1`} {" "}
                    {this.props.duration > 1 ?Translate({ id: "Player.Mins" }):Translate({ id: "Player.min" })}</span>
                            {collectionType ==="featured" &&  <span color="white" spacing="s" style={{color:'grey'}} className="font-caption discIcon"> {getCardsDate(this.props.displayDate)}</span>}
                            <span color="white" spacing="s" style={{color:'grey'}} className="font-caption ">{Translate({ id: `filter.${this.props.level}` })}</span></div>
                        </div>} </div>}

      {this.props.channelDescription && <div className={` ${cardType} makeTextClickable`} >
        <div className={`cardPos comman`} onClick={this.props.clicked}>
          <div  data-testid={"className"} className="imageHolder full-dark-overlay" style={{backgroundImage:`url(${this.props.thumbnail}?width=${getPosterSize(cardType).cardWidth})`}}  >
            <div className="class-card__CardContentTop contentTop">
              <div className="timeholder">
                 <span color="white" className="channelname single-truncate">{this.props.displayName}</span>
              </div>
            </div>

            <div className="class-card__CardContentBottom-sc-8519zh-3 bottomHolder ">
             <img src={this.props.profileImageUrl} className="channelImg" alt="channelImage" />
             <span color="white" spacing="s" className="font-caption truncate"> { this.props.channelDescription}</span>
            </div>
          </div>
        </div>
      </div>}
    </div>

      // <Grid item className="">
      //   <div className={`${!this.props.favDisabled ? "workoutPlayer" : "cotd2"}`}>
      //     <video
      //       style={{
      //         backgroundColor: this.state.isPlaying ? "#000" : "transparent"
      //       }}
      //       id = {`video-${this.props.id}`}
      //       preload="none"
      //       loop
      //       playsInline
      //       ref={this.myRef}
      //       className="Trailer thumbnail"
      //       poster={this.props.thumbnail ?
      //         window.innerWidth >= 720 ?
      //           `${this.props.thumbnail}?width=1200` :
      //           `${this.props.thumbnail}?width=640&height=320` :
      //         ''}
      //       onClick={this.props.clicked}
      //       onMouseOver={(e) => this.startVideo(e.target)}
      //      onMouseOut={e => {
      //         if(this.playerInit !== null){
      //          clearTimeout(this.playerInit);
      //          this.playerInit=null;
      //          e.target.load();
      //          if(this.player){
      //           this.player.remove()
      //          }
      //        }
      //      }}
      //     >
      //       <source
      //         src={uri}
      //         type={this.getMimeType(uri)}
      //       />
      //     </video>
      //   </div>
      //   {/* {this.props.trailerLinkWeb && (
      //     <Button
      //       className={
      //         this.props.isSearchPageCard
      //           ? "trailerButton"
      //           : "slidertrailerButton"
      //       }
      //       style={{
      //         borderRadius: "2px",
      //         textTransform: "uppercase",
      //         // position: "absolute",
      //         padding: "0px",
      //         marginLeft: "20px",
      //         maxWidth: "90px",
      //         maxHeight: "25px",
      //         zIndex: "50000000000 !important",
      //         backgroundColor: "rgb(0, 0, 0, 0.3)",
      //         border: "none !important"
      //       }}
      //       onClick={() => this.startVideo()}
      //       color="secondary"
      //       variant="outlined"
      //     >
      //       {this.state.isPlaying ? (
      //         <Pause className={this.props.classes.pausedButton} />
      //       ) : (
      //           <PlayArrow className={this.props.classes.pausedButton} />
      //         )}
      //       <Typography variant="h4">Trailer</Typography>
      //     </Button>
      //   )} */}
      //   <div className="workout-footer" >
      //     <Grid container justifyContent="flex-start" direction="row">
      //       <Grid item lg={12} md={12} sm={12} xs={12}>
      //         <Grid container direction="row" className="m-t-15 m-t-xs-15">
      //           <Grid item lg={11} md={11} sm={11} xs={11} style={{ overflow: "hidden", textOverflow: "ellipsis", width: '11rem' }}>
      //             <Typography noWrap={this.props.favDisabled ? true : false} variant={`${!this.props.favDisabled ? "subtitle2" : "h3"}`} className="workout-footer-p">
      //               {this.props.title}
      //             </Typography>
      //             {this.state.isClassNew && <NewButton />}
      //           </Grid>
      //           {!((this.props.isClubConnect || this.props.isLiveStream) && config.connectSource!=="wexer") && !this.props.favDisabled && <Grid item lg={1} md={1} sm={1} xs={1} className="text-right">
      //             {this.state.isClassFav && (
      //               <CSSTransition
      //                 classNames="slide"
      //                 timeout={10}
      //                 mountOnEnter
      //                 unmountOnExit
      //                 in={true}
      //                 onClick={this.unfavVideo}
      //               >
      //                 <Button className="fvrt-star">

      //                   <img
      //                     className="favButton"
      //                     src={require("../../../assets/images/unfav-copy.svg")}
      //                     alt="un-fav"
      //                   />

      //                 </Button>
      //               </CSSTransition>
      //             )}
      //             {!this.state.isClassFav && (
      //               <CSSTransition
      //                 classNames="slide"
      //                 timeout={10}
      //                 mountOnEnter
      //                 unmountOnExit
      //                 in={true}
      //                 onClick={this.favVideo}
      //               >
      //                 <Button className="fvrt-star"
      //                   variant="text"
      //                 >

      //                   <img
      //                     className="favButton"
      //                     src={require("../../../assets/images/unfav.svg")}
      //                     alt="fav"
      //                   />

      //                 </Button>
      //               </CSSTransition>
      //             )}
      //           </Grid>}
      //         </Grid>
      //       </Grid>
      //     </Grid>
      //     <div
      //       className="cardDescription-slider"
      //     >
      //       {this.props.description != null && (
      //         <Typography variant="h4" className="cardDescription-p text-gray m-tb-5 m-b-10">
      //           {window.screen.width < 450
      //             ? this.props.description.substring(0, 125)
      //             : window.screen.width > 2200
      //               ? this.props.description.substring(0, 125)
      //               : this.props.description.substring(0, 125)}
      //           {window.screen.width < 450
      //             ? this.props.description[125] != null && <em> ...</em>
      //             : (window.screen.width > 2200
      //               ? this.props.description[125]
      //               : this.props.description[125]) != null && <em> ...</em>}
      //         </Typography>
      //       )}
      //     </div>
      //     <Grid container justifyContent="flex-start" direction="row">
      //       {this.props.duration !== undefined && <Grid item>
      //         <Grid container direction="row" className="">
      //           <Grid item>
      //             <Typography variant="h4" className="cardDescription-p">
      //               {this.props.duration !== 0 ? `${this.props.duration}` : `1`} {" "}
      //               {this.props.duration > 1 ?Translate({ id: "Player.Mins" }):Translate({ id: "Player.min" })}
      //             </Typography>
      //           </Grid>
      //         </Grid>
      //       </Grid>}
      //       <Grid item>
      //         {this.props.level && <Grid container direction="row" className="">
      //           <Grid item className="discIcon">
      //             <Typography variant="h4" className="cardDescription-p"
      //             >
      //               {/* {this.props.level} */}
      //               {Translate({ id: `filter.${this.props.level}` })}


      //             </Typography>
      //           </Grid>
      //         </Grid>}
      //       </Grid>
      //       <Grid item>
      //         {this.props.creationDate && <Grid container direction="row" className="">
      //           <Grid item className="discIcon">
      //             <Typography variant="h4" className="cardDescription-p"
      //             >
      //               {getCardsDate(this.props.creationDate)}
      //             </Typography>
      //           </Grid>
      //         </Grid>}
      //       </Grid>
      //     </Grid>
      //   </div>
      // </Grid>

    );
  }
}

export default (withStyles(style)(Workout))
