import React, { useEffect, useState } from "react";
import Spinner from "./components/spinner/spinner";
import Error from "./components/Error";
import { useEmbedded } from "./hooks/useEmbedded";
import { isEmbeddedMode } from "./utils/utilityfunctions";
import { useNavigate } from "react-router";
const config = require("./assets/config.json");
function EmbeddedModeWrapper(props) {
  //const history = useHistory();// need to upgrade router to use hooks
  // const { history } = props;
  const navigate = useNavigate();
  const {useEmbeddedDispatch,useEmbeddedState} = useEmbedded()
  const {setEmbeddedMode, authenticThirdyPartyPlayer, logOut,loadEndEvent, loadEndErrorEvent} = useEmbeddedDispatch()
  const {isAuthenticated, showErrorMsg,errorMessage} = useEmbeddedState()
  // const {isAuthenticated,loading,showErrorMsg} = embeddedState();
  // const {setEmbeddedMode,authenticThirdyPartyPlayer,logOut} = embeddedDispatch();
  const [showLoader, setShowLoader] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    let embededConfig = props.embededConfig;
    //Check if Object is Empty
    if (
      !(
        embededConfig &&
        Object.keys(embededConfig).length === 0 &&
        embededConfig.constructor === Object
      )
    ) {
      localStorage.setItem("SSOToken",embededConfig?.token);
      setEmbeddedMode(embededConfig)
      config["embeddedConfig"]["isEmbedded"] = true;
      config["embeddedConfig"]["showFooter"] = false;
      if(config.embeddedModeOnly && config.embeddedConfig){
// eslint-disable-next-line no-self-assign
        config["embeddedConfig"]["showLogo"] = config.embeddedConfig.showLogo;
      } else config["embeddedConfig"]["showLogo"] = false;

      config["embeddedConfig"]["showSignInPage"] = false;
      config["embeddedConfig"]["showSignUpPage"] = false;
      if (embededConfig?.token && embededConfig.token !== "") {
        if (
          embededConfig.mode &&
          embededConfig.mode.toLowerCase() === "seamless"
        ) {
          authenticThirdyPartyPlayer(embededConfig.token)
        } else {
          //tenantConfig.signInFormFeilds[2].formItems.isDisabled = false;
          //tenantConfig.signUpFormFeilds[2].formItems.isDisabled = false;
          loadEndEvent();
          navigate( "./" );
          setShowLoader(false)
        }
      }
    }
    // eslint-disable-next-line eqeqeq
    else if (config.embeddedModeOnly == null ||
      (config.embeddedModeOnly && config.embeddedModeOnly === false)) {
      loadEndEvent();
      setShowLoader(false)
    }
    // if (config["webplayerMode"] === "standard") {
    //     if (localStorage.getItem("token") === null || localStorage.getItem("userId") === null) {
    //         navigate({ pathname: "./signin" })
    //     }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (showErrorMsg === true) {
      logOut()
      loadEndErrorEvent(errorMessage);
      if(config?.ssoLogin === true){
        if(isEmbeddedMode()){
          setError(true);
          setShowLoader(false);
        }
        else{
          navigate("/signin?SSO=false")
        }
      }else{
        setError(true);
        setShowLoader(false);
      }
    }else if (showErrorMsg === false) {
      loadEndEvent();
      setError(false);
      setShowLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showErrorMsg]);
  // useEffect(() => {
  //   setShowLoader(loading);
  // }, [loading]);
  useEffect(() => {
    if (isAuthenticated) {
          if(showLoader){
            setShowLoader(false)
          }
      if(props.embededConfig &&
         props.embededConfig["content-mode"] &&
         props.embededConfig["content-mode"].toLowerCase() === "tenant") {
          config["embeddedConfig"]["content-mode"] = "tenant";
          navigate( "/connect/clubList" );
         }
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);
  return (
    <React.Fragment>
      {showLoader ? <Spinner /> : error ? <Error /> : props.children}
    </React.Fragment>
  );
}
export default EmbeddedModeWrapper;