import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import CardShimmer from './CardShimmer';

const ClassOfTheDayShimmer = (props) => {
    
    return (
        <div data-testid={"cotdShimmer"} className="liveShimmer mobileScroll m-t-15">
            <Skeleton variant="text" width={'30%'} height={25} />
            <Skeleton variant="text" width={'40%'} height={15} />
            <CardShimmer noOfCards={[1,2,3]} footer={true} cardType='cotd-card'/>
        </div>
        // <Grid item lg={12} xs={12} sm={12} md={12}>
        //     <div  data-testid={"cotdShimmer"} className="cotdShimmer mobileScroll m-t-30 m-b-xs-10">
        //         <Skeleton variant="text" width={'30%'} height={25} />
        //         <Skeleton variant="text" width={'80%'} height={15} />
        //         <div className='codBg m-t-8'>
        //             <Grid container justifyContent="flex-start" className='resposibleGrid cotd cotdShimmerCards'>
        //                 <Grid item xs={12} sm={12} md={12} lg={7} >
        //                     <Skeleton variant="rect" className="Trailer thumbnail cardBorder" width={'90%'} style={{minHeight:"40vh"}} />
        //                 </Grid>

        //                 {isIpad === false && <Grid item xs={12} sm={6} md={12} lg={5} className="loginButtonHolder cotd2 hidden-md ">
        //                     <Grid container spacing={4} >
        //                         {[1, 2, 3, 4].map((i) =>
        //                             <Grid key={i} item xs={12} sm={6} md={3} lg={6}>
        //                                 <Skeleton variant="rect" className="cotd cardBorder" height={144} />
        //                                 <Skeleton variant="text" width={'40%'} height={13} />
        //                             </Grid>)}
        //                     </Grid>
        //                 </Grid>}
        //             </Grid>
        //         </div>
        //     </div>
        // </Grid>
    );
}
export default ClassOfTheDayShimmer;
