import React from "react";


import Grid from "@material-ui/core/Grid";
import {  Typography } from "@material-ui/core";
import { Translate } from "../../utils/Translate";
import ChannelsCardWrapper from "./ChannelsCardWarpper";
import Spinner from "../../components/spinner/spinner";
import CustomGrid from "../CustomGrid/CustomGrid";
import { getImage } from "utils/utilityfunctions";

class ChannelsCollection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      channels: [],
      isloading: true,
    };
  }

  componentDidMount() {
   this.handelMount();
   this.props.clearClassData();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.allChannels !== this.props.allChannels) {
      this.updateChannelsState();
    }
  }
  handelMount(){
    window.scrollTo(0, 0);
    if (this.props.allChannels === null || this.props.allChannels.length === 0) {
      this.props.fetchAllChannels();
    } else {
      this.setState({ channels: this.props.allChannels,isloading:false });
    }
  }
  updateChannelsState = () => {
    if (this.props.allChannels && this.props.allChannels.length > 0) {
      this.setState({ channels: this.props.allChannels,isloading:false });
    }
  };

  ViewChannel = () => {
    this.props.navigate(
      "/channelView",
      {state: { clubClasses: this.props.allChannels }});
  };
  render() {
    let {isloading} =this.state;
    const image = getImage('channel-banner', 'jpg')
    return (
      <div>
        <Grid container>
          <div className="fixedWidthContainer container channelbg-banner main-margin">
                    {
                        <div className="bg-placeholder">
                            <div className="fallbackBox" />
                            <img src={image} className="bg-banner" alt="background" />
                        </div>
                    }

                    <div className="overlay banner-overlay ">
                        <div style={{ zIndex: 1 }} className="page-heading text-white">
                            <Typography variant="h1" className="m-t-xs-5 font-h1">
                            {Translate({ id: "homePage.ChannelsTitle" })}
                            </Typography>
                            <Grid container direction="row">
                                <Grid item lg={12} md={12} className="text-center channelDescription hidden-xs">
                                    <Typography className="padding16 font-h6" variant="h6" >
                                    {Translate({ id: "homePage.ChannelsDescription" })}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
          </Grid>
          <div className="pseudo-div">
            
          </div>
          <CustomGrid
            items={this.state.channels}
            totalItems={this.state.channels.length}
            showLoading={isloading}
            classes={isloading ? 'cardList' : 'cardList no-hidden'}
            containerClass="m-bt-lg-30 m-t-xs-20 channelSection main-page-container list-container-gap"
            loader={<Spinner backColor="white" />}
            listClass={'list list-live-page'}
            >
           { (data => <ChannelsCardWrapper data={data} cardType="channel-page-card"/>)}
          </CustomGrid>
      </div>
    );
  }
}

export default ChannelsCollection;
