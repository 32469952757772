import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import IconBackArrow from "../../../CommonImages/icon-backArrow";
import { Skeleton } from "@material-ui/lab";
import { Button, Typography } from "@material-ui/core";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Translate } from "../../../utils/Translate";
import Routes from "../../../components/Router/Routes";
import VideoPlayer from "../../../hoc/VideoPlayerContainer";
import { usePlayListPlayer } from "../../../hooks/usePlayListPlayer";
import {
  FetchPlayBackLink,
  fetchStreamingLinkPlayList,
  logOnDemandTracking,
  resetStreamingLink,
} from "../../../store/actions/ondemand";
import "./PlayListPlayer.scss";
import "../../../theme/styles/video-js.css";
import {
  getTime,
  getTranslatedFile,
  videoPlayerState,
} from "../../../utils/utilityfunctions";
import { map, findIndex } from "lodash";
import { usePlayListInfo } from "../../../hooks/usePlayListInfo";
import { postWorkoutResult } from "../../../store/actions/workout";
import { PlayListPlayerShimmer } from "./PlayListPlayer.shimmer";
import { GetUserSubscription } from "../../../store/actions/subscription";
import CustomDialog from "../../../components/Modal/Modal";
import Spinner from "../../../components/spinner/spinner";
import Close from "CommonImages/close";
const config = require("../../../assets/config.json");
var moment = require("moment");
const PlayListPlayer = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { playListInfo } = usePlayListInfo(props?.playlistId);
  const backToPlaylist = () => {
    navigate(Routes.userPlayList + "/" + props?.playlistId);
  };
  const { title, description } = playListInfo;
  const { loading } = props;

  const navigate = useNavigate();
  const { usePlayListPlayerDispatch, usePlayListState } = usePlayListPlayer(
    props?.playlistId
  );

  const {
    isSubscriptionLoading,
    hasPlayerAccess,
    streamingVideoLink,
    isStreamingLinkLoading,
  } = usePlayListState();
  const playList = useSelector((state) => state.playList);
  const [playlistClasses, setPlaylistClasses] = useState([]);

  const [streamingUrl, setStreamingUrl] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [playListTitle, setPlayListTitle] = useState("");
  const [playListDesc, setPlayListDesc] = useState("");
  const [playingClass, setPlayingClass] = useState("");
  const [selecteditemIndex, setSelectedItemIndex] = useState(0);
  const [ClassDetailsId, setClassDetailsId] = useState(null);
  const loopEnabled = JSON.parse(localStorage.getItem("isLoop"));
  const [isShimmer, setShimmer] = useState(false);
  const [isPageShimmer, setPageShimmer] = useState(true);
  const [isautostart, setAutoStart] = useState(true);
  const playingClassRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const userId = localStorage.getItem("userId");
  const classPlaying = useRef(false);
  const [firstTimeDataLoad, setFirstTimeDataLoad] = useState(true);

  let state = videoPlayerState();

  const [setState, setVideoState] = useState(state);

  const {
    classStarted,
    classClosed,
    fetchPlayListInfo,
    setPlayerWasOnFullScreen,
  } = usePlayListPlayerDispatch();

  useEffect(() => {
    setPlayerWasOnFullScreen(false);
    if (props?.playlistId) {
      //fetchPlayListInfo(props?.playlistId);
      if (!isSubscriptionLoading) getUserSubscription();
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetStreamingLink());
    };
  }, []);

  const getUserSubscription = () => {
    dispatch(GetUserSubscription());
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (!isSubscriptionLoading && isSubscriptionLoading !== null) {
      if (hasPlayerAccess === true) {
        setVideoState({ ...setState, videoPlaying: true, loading: false });
      } else if (hasPlayerAccess === false) {
        if (config?.showUpgradeSubscriptionPopUp !== undefined) {
          setVideoState({ ...setState, showDialog: true, loading: false });
        } else {
          navigate("/subscribe");
        }
      }
    }
  }, [isSubscriptionLoading]);

  useEffect(() => {
    if (
      playList?.info?.list &&
      playList?.info?.list.length > 0 &&
      firstTimeDataLoad
    ) {
      const playablePlayListData = playList?.info?.list?.filter(
        (playListDetail) => playListDetail.isPlayable
      );
      setPlayingClass(playablePlayListData[0]);
      setClassDetailsId({ id: playablePlayListData[0]?.tag });
      setPlayListTitle(title);
      setPlayListDesc(description);
      setPlaylistClasses(
        playablePlayListData.slice(0, playablePlayListData.length)
      );
      setPageShimmer(false);
      //playingClassRef.current = playablePlayListData[0];
      setThumbnailUrl(playablePlayListData[0]?.imageLink);
      setFirstTimeDataLoad(false);
    }
  }, [playList]);

  useEffect(() => {
    if (
      (playingClass &&
        playingClass?.tag &&
        playingClassRef?.current?.playListItemId !==
          playingClass?.playListItemId) ||
      (playingClass !== undefined && playingClassRef?.current === undefined) ||
      (playingClass &&
        playingClass?.tag &&
        loopEnabled &&
        playlistClasses.length == 1)
    ) {
      dispatch(fetchStreamingLinkPlayList(playingClass?.tag));
      playingClassRef.current = playingClass;
    }
  }, [playingClass]);

  useEffect(() => {
    if (isStreamingLinkLoading) {
      setIsLoading(isStreamingLinkLoading);
    }
  }, [isStreamingLinkLoading]);

  useEffect(() => {
    if (streamingVideoLink && streamingVideoLink.results?.streamingUrl) {
      setIsLoading(false);
      let videoState = {
        url: streamingVideoLink.results?.streamingUrl,
        imageLink: thumbnailUrl,
        alternateLink: thumbnailUrl,
        thumbnail: thumbnailUrl,
      };
      setStreamingUrl(streamingVideoLink.results?.streamingUrl);
      setShimmer(false);
      setVideoState({
        ...setState,
        thumbnail: thumbnailUrl,
        url: streamingVideoLink.results?.streamingUrl,
        video: videoState,
        eventId: ClassDetailsId?.id,
        classId: ClassDetailsId?.id,
      });
    } else if (
      !isStreamingLinkLoading &&
      streamingVideoLink &&
      streamingVideoLink?.results?.streamingUrl === ""
    ) {
      //Skip to next
      setTimeout(() => {
        setNextItem(0);
      }, 1000);
    }
  }, [streamingVideoLink]);

  const setNextItem = (elapsedTime, islogging, istracking) => {
    if (elapsedTime > 0) {
      //need to do code for ondemand tracking code
      onDemandTrackingCall(elapsedTime, userId);
    }
    if (elapsedTime > 59) {
      postWorkoutResultsData(userId, elapsedTime);
    }

    const currentIndex = playlistClasses.findIndex(
      (item) => item.playListItemId === playingClass.playListItemId
    );

    if (currentIndex !== -1 && currentIndex < playlistClasses.length - 1) {
      setStreamingUrl("");
      const nxtClass = playlistClasses[currentIndex + 1];
      setNextItemValues(nxtClass, currentIndex + 1);
      setAutoStart(true);
    } else if (currentIndex !== -1 && loopEnabled) {
      setStreamingUrl("");
      const nxtClass = playlistClasses[0];
      setNextItemValues(nxtClass, 0);
      setAutoStart(true);
      if (playlistClasses.length == 1) {
        setPlayingClass("");
        setShimmer(false);
        return;
      }
    } else {
      if (
        playlistClasses.length == 1 ||
        (currentIndex === playlistClasses.length - 1 && !loopEnabled)
      ) {
        setIsLoading(false);
        if (islogging && istracking) {
          setAutoStart(false);
          setShimmer(false);
          return;
        }
        setShimmer(false);
      }
      setStreamingUrl("");
      const nxtClass = playlistClasses[playlistClasses.length - 1];
      setNextItemValues(nxtClass, currentIndex);
    }
  };

  const postWorkoutResultsData = (userId, elapsedTime) => {
    const sClass = {
      classCommitmentTag: null,
      primaryGoal: 0,
      secondaryGoal: 0,
      skillLevel: 0,
      durationSeconds: elapsedTime,
      mood: null,
      completedDate: new Date(),
      tag: playingClass.tag,
      intensityLevel: 0,
      workoutName: playingClass.title,
      userWorkoutTag: playingClass.tag,
      isTimed: false,
      calorieBurn: 0,
      exercises: null,
      durationMinutes: Math.ceil(elapsedTime / 60),
      trackingResult: 7, // 9 use kiya hain useLiveStreamPlayer.js
      imageUrl: playingClass.imageLink,
      userId: userId,
      trailerLinkWeb: "",
      workoutDescription: "",
      creationDate: new Date(),
      keywords: "",
      classCategories: "",
      level: "",
      deviceId: "",
    };
    dispatch(postWorkoutResult(sClass));
  };
  const onDemandTrackingCall = (elapsedTime, userId) => {
    const selectedClass = {
      requestSource: 1,
      subscriptionTag: "",
      performDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
      providerName: "",
      providerId: config.providerId,
      id: playingClass.tag,
      contentName: playingClass.title,
      actualDurationSecond: playingClass.duration,
      playedDurationSecond: elapsedTime,
      subscriptionStartDate: "",
      userId: userId,
      deviceId: "",
      ID: userId + "-" + playingClass.tag + "-" + moment().unix(),
    };
    dispatch(
      logOnDemandTracking(selectedClass, userId, elapsedTime, false) //isClubConnect
    );
  };

  const elapsedTimeLiftstateUp = (time) => {
    if (time > 0) {
      classPlaying.current = true;
    }
  };
  const setNextItemValues = (nxtClass, currentIndex) => {
    setTimeout(() => {
      setPlayingClass(nxtClass);
      //playingClassRef.current = nxtClass;
      setThumbnailUrl(nxtClass.imageLink);
      setSelectedItemIndex(currentIndex);
      handleItemSelect(currentIndex);
    }, 100);
  };
  const handleItemSelect = (index) => {
    const element = document.getElementById(`play-${index}`);
    if (element !== null && element !== undefined) {
      element.scrollIntoView({
        block: "center",
        block: "nearest",
        behavior: "smooth",
      });
    }
  };

  const playListImageClick = (selectedClass, index) => {
    if (playingClass?.tag !== selectedClass?.tag) {
      setStreamingUrl("");
    }
    classPlaying.current = false;
    setSelectedItemIndex(index);
    setShimmer(true);
    setPlayingClass(selectedClass);
    //playingClassRef.current = selectedClass;
    setThumbnailUrl(selectedClass.imageLink);
    setAutoStart(true);
    setTimeout(() => {
      if (playlistClasses.length == 1) {
        setShimmer(false);
      }
    }, 500);
    setTimeout(() => {
      setShimmer(false);
    }, 2000);
  };

  const nextClassPlay = (props) => {
    const selectedClaas = playlistClasses.findIndex(
      (item) => item.tag === props.videoProps.classId
    );
  };

  const getMediaScreenResolution = () => {
    const mediaQuery = window.matchMedia("(pointer: coarse)");
    return mediaQuery?.matches;
  };

  const trackAndLogClass = (islogging, istracking, elapsedTime = 0) => {
    if (istracking && islogging) {
      setShimmer(true);
      classPlaying.current = false;
      setNextItem(elapsedTime, islogging, istracking);
    }
  };

  const okButtonClick = () => {
    setVideoState({ ...setState, showDialog: false });
    if (config?.UpgradeRedirect != null) {
      window.open(config.UpgradeRedirect, "_blank");
      props.navigate(-1);
    }
  };
  return (
    <div
      className={
        isShimmer
          ? "m-t-xs-5 play-list-info loading"
          : "m-t-xs-5 play-list-info "
      }
    >
      {isPageShimmer ? (
        <PlayListPlayerShimmer />
      ) : (
        <div className="play-list-info-details">
          <div className="play-list-info-heading">
            <div
              onClick={backToPlaylist}
              className="play-list-info-heading-icon"
            >
              <IconBackArrow id="play-list-info-back-arrow" color={config.primaryColor}/>
            </div>
            <div className="play-list-info-heading-title">
              {loading ? (
                <Skeleton variant="rect" width={"100%"} height={50} />
              ) : (
                <span>{playListTitle}</span>
              )}
            </div>
            <div className="play-list-info-heading-controls">
              <div className="play-list-info-heading-text mt20">
                <div
                  className="rectangle-playerlist-crossicon"
                  onClick={backToPlaylist}
                >
                  <Close onClick={backToPlaylist} color={config.primaryColor}/>
                </div>
              </div>
              <div className="play-list-info-heading-btn"></div>
            </div>
            <div className="play-list-info-description play-list-player">
              {playListDesc}
            </div>
          </div>
          <div
            className="p-t-player p-t-xs-16 info-container m-b-info-cont"
            id="playingClassRef"
          >
            {setState?.showDialog ? (
              <CustomDialog
                open={true}
                title={Translate({ id: "Subscription.UpgradeMembership" })}
                heading={Translate({ id: "Subscription.UpgradeMessage" })}
              >
                <div className="text-center padding16">
                  <Button
                    size="small"
                    type="submit"
                    color="secondary"
                    className="button btn-default"
                    variant="contained"
                    onClick={okButtonClick}
                    style={{ marginTop: "60px" }}
                  >
                    <Typography variant="h5">
                      {Translate({ id: "Subscription.Ok" })}
                    </Typography>
                  </Button>
                </div>
              </CustomDialog>
            ) : null}
            <div className="video-player play-list-video-player" id="playerRef">
              {isShimmer || isLoading ? <Spinner backColor="white" /> : null}
              {isShimmer == false &&
              streamingUrl.length > 0 &&
              setState?.classId !== null &&
              setState?.eventId !== undefined &&
              streamingUrl.length > 0 !== null ? (
                <VideoPlayer
                  autostart={isautostart}
                  classStarted={classStarted}
                  classClosed={classClosed}
                  paused={true}
                  elapsedTimeLiftstateUp={elapsedTimeLiftstateUp}
                  trackAndLogClass={trackAndLogClass}
                  props={state}
                  videoProps={setState}
                  ClassDetails={ClassDetailsId}
                  nextClassPlay={nextClassPlay}
                  PlaylistPlayer="PlaylistPlayer"
                  navigate={navigate}
                  location={location}
                />
              ) : (
                <img
                  className="video-wrapper"
                  src={`${thumbnailUrl}`}
                  alt="No-Video"
                />
              )}
            </div>
            <div
              className="class-side-info right-bar"
              id="playingClassDetailRef"
            >
              <div className="play-list-item  no-drag">
                {map(playlistClasses, (item, index) => (
                  <div key={Math.random()} id={`play-${index + 1}`}>
                    <div
                      className="play-list-item-image player-item"
                      onClick={
                        getMediaScreenResolution()
                          ? () => {
                              playListImageClick(item, index);
                              handleItemSelect(index + 1);
                            }
                          : null
                      }
                    >
                      <span
                        className="sp-inblock"
                        onClick={
                          !getMediaScreenResolution()
                            ? () => {
                                playListImageClick(item, index);
                                handleItemSelect(index + 1);
                              }
                            : null
                        }
                      >
                        <img
                          className={`${
                            selecteditemIndex === index
                              ? "playlist-selected"
                              : "playlist-unselected"
                          } player-video`}
                          alt={thumbnailUrl}
                          src={item.imageLink}
                        />
                      </span>
                      <span className="left-title">
                        <div className="play-list-item-class-info-heading">
                          {item?.title}
                        </div>
                        <div className="play-list-item-class-info-time text-gray duration-text">
                          {getTime(item?.duration)}
                        </div>
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

PlayListPlayer.propTypes = {};

export { PlayListPlayer };
