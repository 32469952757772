/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer } from "react";
import { isEmpty } from "lodash";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { Translate } from 'utils/Translate';
import Workout from "hoc/WorkoutContainer"
import Routes from 'components/Router/Routes';
import InfiniteScroll from "react-infinite-scroller";
import LoadingClassesShimmer from "components/Shimmer/LoadingClassesShimmer";
import { LAZY_LOADING_PAGE_SIZE } from "utils/constants";
import { useChannelView } from "hooks/useChannelView";
import { getPosterSize } from "utils/utilityfunctions";
import { useLocation, useNavigate } from "react-router";

const ChannelView = (props) => {
    //window.scrollTo(0, 0);
    const navigate = useNavigate();
    const {pathname : path,state } = useLocation();
    const previousPageData = state ? state.clubClasses : null;
    const { useChannelViewState, useChannelViewDispatch,channelReducer } = useChannelView()
    const { channelsTiltes, isClassesLoading, isCalled } = useChannelViewState()
    const { fetchAllClassesForChannel,clearAllChannelClasses,saveDeeplinkPayload } = useChannelViewDispatch()
    const { setReducerData,useChannelReducer,initialState} = channelReducer;
    const [channelState, channelDispatch] = useReducer(useChannelReducer, initialState);
    const { channelsData,titlesToDispay,hasMoreData,pageNumber,isLoading,itemRemaining } = channelState;

    useEffect(()=> {
        return () => clearAllChannelClasses()
    },[])
    useEffect(() => {
        if (channelsTiltes) {
            let resData= {};
            if (titlesToDispay === null) {
                // If no titles are available 
                setReducerData(channelDispatch,{ titlesToDispay:channelsTiltes})
                if (channelsData === null) {
                    const data = channelsTiltes.channelDetails;
                    let newChannelData;
                    if (data.displayName !== null) {
                        let channelDescription = data.description.invariantText
                        if (channelDescription == null) {
                            channelDescription = data.description
                        }
                        let channelDisplayName = data.displayName.invariantText
                        if (channelDisplayName == null) {
                            channelDisplayName = data.displayName
                        }
                        newChannelData = {
                            active: data.active,
                            availability: data.availability,
                            description: channelDescription,
                            displayName: channelDisplayName,
                            mediaSpaceImageUrl: data.mediaSpaceImageUrl,
                            name: channelDisplayName,
                            profileImageUrl: data.profileImageUrl,
                            provider: data.provider,
                            scheduleDate: data.createdDate, // Need to check if correct date is being used
                            tag: data.tag,
                            tenants: data.tenants,
                            title: data.title,
                        }
                        setReducerData(channelDispatch,{channelsData : newChannelData})
                    } else {
                        navigate(Routes.homePage);
                    }
                }
            }
            else {
                // || RR|| Getting new titles/Lazily Loaded titles and merging them into existing one. ||
               resData =  {...resData,titlesToDispay:{ ...channelsTiltes, items: [...titlesToDispay?.items ?? [], ...channelsTiltes?.items ?? []] }};
            }
            if (channelsTiltes.itemsRemaining > 0) {
                resData =  {...resData,hasMoreData : true}
            }
            else {
                resData =  {...resData,hasMoreData : false}
            }
            setReducerData(channelDispatch,{...resData});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [channelsTiltes])

    useEffect(() => {
        //RR
        //get the list of channles of that title
        if(!isEmpty(previousPageData)){
            const checkCall = channelsTiltes?.channelDetails?.tag === undefined  ? isCalled : channelsTiltes?.channelDetails?.tag !== previousPageData.tag;
            if (checkCall) {
                setReducerData(channelDispatch,{
                    channelsData : previousPageData,
                    titlesToDispay:null,
                    // isLoading:true,
                    pageNumber:pageNumber + 1
                })
                fetchAllClassesForChannel(previousPageData.tag, LAZY_LOADING_PAGE_SIZE, pageNumber);
            }
        } else if (previousPageData === null) {
            if (path) {
                let channelTag = path.split("/")
                if (channelTag.length > 1) {
                    const id = channelsTiltes?.titleDetails?.channelId ?? channelTag[2];
                    if(titlesToDispay && !isCalled && titlesToDispay?.items === undefined){
                        fetchAllClassesForChannel(id, LAZY_LOADING_PAGE_SIZE, 1)
                    }
                    isCalled && fetchAllClassesForChannel(id, LAZY_LOADING_PAGE_SIZE, 1)
                    /**
                     * Nikhil Gupta
                     * CF-9278
                     * Deeplinking for signedout users
                     */
                    saveDeeplinkPayload(path)
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previousPageData,titlesToDispay])
    
    const onCardClick = (titleDetail) => {
        let channelPayload = {}
        if (previousPageData) {
            channelPayload = previousPageData;
        }
        else {
            channelPayload = {
                ...channelsData
            }
        }
        let data = {
            ...channelPayload,
            title: [titleDetail]
        }
        navigate(Routes.onDemandClassPlayerRoute + titleDetail.tag,
            {state: { selectedItem: data, from: 'CHANNEL' }} );
        // props.history.push({
        //     pathname: Routes.channelsPlayer + titleDetail.channelId + "/classes" + "/"+ titleDetail.tag,
        //     state: {
        //         selectedItem: {
        //             ...channelPayload,
        //             title: [titleDetail]
        //         }
        //     },
        // });   
    }
    const getTagId = ()=>{
        let channelTag = path.split("/");
        return previousPageData?.tag ?? channelsTiltes?.titleDetails?.channelId ?? channelTag[2];
    }
    const loadMore = () => {
        setTimeout(() => {
            if (titlesToDispay.itemsRemaining > 0 && !isClassesLoading && itemRemaining !== titlesToDispay.itemsRemaining) {
                setReducerData(channelDispatch,{
                    hasMoreData : false,
                    pageNumber : pageNumber + 1,
                    itemRemaining : titlesToDispay.itemsRemaining
                })
                const currentPageNumber =  pageNumber === 1 ? pageNumber +1 : pageNumber;
                // after page reload check on load more records
                // if (titlesToDispay.itemsRemaining >= LAZY_LOADING_PAGE_SIZE) {
                fetchAllClassesForChannel(getTagId(), LAZY_LOADING_PAGE_SIZE, currentPageNumber)
                // }
                // else {
                //     dispatch(fetchAllClassesForChannel(previousPageData.tag, titlesToDispay.itemsRemaining, pageNumber));
                // }
            }
        }, 1000);
    }
    const isFavourite = (title) => {
        if (props.collectionObject) {
            var favColloectionIndex = props.collectionObject.findIndex((favColloection) => favColloection.collectionName === "Favourites")
            if (props.collectionObject[favColloectionIndex].collectionItems.findIndex(reqclass => reqclass.tag === title.tag) > -1) {
                return true
            }
        }
        return title.favourite
    }
    const getChannelDescription = (channelData) => {
        if (channelData == null) {
            return null
        }
        let channelDescription = channelData.description.invariantText
        if (channelDescription == null) {
            channelDescription = channelData.description
        }
        return channelDescription;
    }
    const getChannelDisplayName = (channelData) => {
        if (channelData == null) {
            return null
        }
        let channelDisplayName = channelData.displayName.invariantText
        if (channelDisplayName == null) {
            channelDisplayName = channelData.displayName
        }
        return channelDisplayName;
    }
    const checkFavStatus = (selectedClassTag) => {
        let classes = titlesToDispay;
        let classIndex = classes.items.findIndex(particularclass => particularclass.tag === selectedClassTag)
        if (classIndex !== -1) {
            classes.items[classIndex].favourite = !classes.items[classIndex].favourite
        }
    }
    return (
        <div className="detail-page">
            <Grid container id='channelBg'>
                <div className="fixedWidthContainer container channelbg-banner main-margin">
                    <div className="fallbackBox" />
                    {
                        channelsData &&
                        <img src={channelsData && channelsData.mediaSpaceImageUrl+`?width=${getPosterSize().channelBanner}`} className="bg-banner" alt="backgroung"/>
                    }

                    <div className="overlay banner-overlay ">
                        <div style={{ zIndex: 1 }} className="page-heading text-white">
                            <div className="channelbgimg">
                            {channelsData && channelsData?.profileImageUrl && 
                                <img src={channelsData && channelsData?.profileImageUrl} className="channel-profileImg" alt="profile"
                                />}
                            <Typography variant="h1" className="m-t-xs-5 m-b-0">
                                {getChannelDisplayName(channelsData)}
                            </Typography>
                            </div>
                            <Grid container direction="row">
                                <Grid item lg={12} md={12} className=" channelDescription chnl-detail-desc hidden-xs">
                                    <Typography className="padding16 font-h6" variant="h6" >
                                        {getChannelDescription(channelsData)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
                {/* <div className="container hidden-lg centerContent">
                    <Grid container direction="row">
                        <Grid item lg={12} md={12} sm={12} xs={12} className="text-center channelDescription text-gray m-t-xs-5">
                            <Typography className="padding16" variant="h6" >
                                {getChannelDescription(channelsData)}
                            </Typography>
                        </Grid>
                    </Grid>
                </div> */}
            </Grid>
            <div className="pseudo-div">
            
            </div>
            { (titlesToDispay && titlesToDispay.items) ?
                <div className="main-page-container list-container-gap">
                    <InfiniteScroll
                        datalength={LAZY_LOADING_PAGE_SIZE}
                        loadMore={loadMore}
                        hasMore={hasMoreData}
                        loader={<LoadingClassesShimmer />}
                    >

                        <div className="">
                            {isLoading ?
                           <LoadingClassesShimmer />:
                                <div className="">
                                    {titlesToDispay.items.length === 0 ?
                                        <div item>
                                            <Typography className="text-gray align-left p-t-32 m-t-xs-15" variant="h6" >
                                                {Translate({ id: "Channels.NoData" })}
                                            </Typography>
                                        </div> :
                                        <>
                                        
                                        <div className="list list-live-page ">
                                        {titlesToDispay.items && titlesToDispay.items.map((title, index) =>  
                                                <Workout
                                                    key={index}
                                                    displayDate={title.scheduleDate}
                                                    trailerLinkWeb={title.trailerLinkWeb}
                                                    history={props.history}
                                                    showFav={true}
                                                    thumbnail={title.imageLink}
                                                    title={title.className}
                                                    duration={title.durationSecond
                                                        ? Math.ceil(title.durationSecond / 60)
                                                        : title.durationMinutes}
                                                    description={title.classDescription}
                                                    show={true}
                                                    id={title.tag}
                                                    clicked={() => onCardClick(title)}
                                                    level={title.level}
                                                    favCallback={() => { checkFavStatus(title.tag) }}
                                                    isFav={isFavourite(title)}
                                                    cardType="channel-page-card"
                                                    collectionType="featured"
                                                />
                                        )}
                                        </div>
                                        </>}
                                </div>
                            
                            }
                        </div>
                    </InfiniteScroll>
                </div>
                :
                <div className="main-page-container list-container-gap">
                    <LoadingClassesShimmer />
                </div>
            }
        </div >)
}
export default ChannelView