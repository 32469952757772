import React, { useEffect, useState } from 'react';
import ClassOfTheComponent from './ClassOfTheDayComponent'
import Routes from '../../Router/Routes'
import config from "../../../assets/config.json"
import logger from "../../../utils/logger"
import ResCard from "../../../CardVersions/version1/newcard"
import { useNavigate } from 'react-router';


function Cotd(props) {
    const [classOfTheDay, setClassOfTheDay] = useState(null);
    const cardVersion = config.cardVersion
    const navigate = useNavigate();
    useEffect(() => {
        if (Object.keys(props.cotdObj).length > 0) {
            if (props.cotdObj.collectionType === "classoftheday" &&
             (props.cotdObj.collectionItems != null) && 
             (props.cotdObj.collectionItems.length > 0)) {
                logger(props.cotdObj);
                setClassOfTheDay(props.cotdObj);
            }
        }

    }, [props.cotdObj]);
    const takeUserToClassDetail = (index) => {
            navigate(Routes.onDemandClassPlayerRoute + classOfTheDay.collectionItems[index].tag,
                    {state: { selectedItem: classOfTheDay.collectionItems[index] } } );
    };
    return (
        <React.Fragment>
            { config.showClassOfTheDay && classOfTheDay != null &&
                    <div className="m-t-60 ">
                        {cardVersion === 0.01 && <ClassOfTheComponent takeUserToClassDetail={takeUserToClassDetail} classOfTheDay={classOfTheDay} />}
                        {cardVersion === 0.0 && <ResCard collection={classOfTheDay} cardType="cotd-card " list="cotd-list"  takeUserToClassDetail={takeUserToClassDetail}/>}
                        {cardVersion === 0.1 && <ClassOfTheComponent classOfTheDay={classOfTheDay} cardType="cotd-card " list="cotd-list"  takeUserToClassDetail={takeUserToClassDetail}/>}
                    </div>
            }

        </React.Fragment>
    )
}

export default Cotd;
