import React, { useEffect } from 'react'
import Slider from "hoc/SliderContainer"
import { Typography, Grid } from '@material-ui/core';
import { useSelector } from "react-redux";
import { Translate } from "../../../../utils/Translate";
import LoadingClassesShimmer from "../../../../components/Shimmer/LoadingClassesShimmer";
import { useBrowseCollections } from "../../../../hooks/useBrowseCollections"
import { getImage } from 'utils/utilityfunctions';
import { useNavigate } from 'react-router';

const BrowseCollections = (props) => {
    const { useBrowseCollectionsDispatch } = useBrowseCollections()
    const { fetchOnDemandClasses } = useBrowseCollectionsDispatch()
    const userId = localStorage.getItem("userId");

    const collectionArray = useSelector(state => state.onDemand.onDemandCollection)
    useEffect(() => {
        fetchOnDemandClasses(userId ? userId : "")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const src = getImage('collection-banner', 'jpg')

    const navigate = useNavigate();
    return (
        <div className='main-margin collectionPage-main'>

            <Grid container id='channelBg  '>
                <div className="fixedWidthContainer container channelbg-banner">
                    {
                        <div className="bg-placeholder transition">
                            <div className="fallbackBox" />
                            <img src={src} className="bg-banner" alt='collection' />
                        </div>
                    }

                    <div className="overlay banner-overlay text-center">
                        <div style={{ zIndex: 1 }} className="page-heading text-white">
                            <Typography variant="h1" className="m-t-xs-5">
                                {Translate({ id: "ClassListViews.Collections" })}
                            </Typography>
                            <Grid container direction="row">
                                <Grid item lg={12} md={12} className="text-center channelDescription hidden-xs">
                                    <Typography className="padding16 font-h6" variant="h6" >
                                        {Translate({ id: "ClassListViews.CollectionDescription" })}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
                <div className="page-container hidden-xs">
                    <Grid container direction="row">
                        <Grid item lg={12} md={12} xs={12} sm={12} className="text-center channelDescription text-gray m-t-xs-5">
                            <Typography variant="h6" >
                                {Translate({ id: "ClassListViews.CollectionDescription" })}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            <div className="pseudo-div">
            
          </div>
            <div className="collection-list collectionPage main-page-container list-container-gap">
                {collectionArray ? (collectionArray.length > 0 && collectionArray.map(
                    collection => (
                        (collection.collectionName && collection.collectionName.toLowerCase() !== "favourites") &&
                        (collection.collectionType.toLowerCase() !== "classoftheday") && collection.collectionItems && collection.collectionItems.length > 0 &&
                        <Slider
                            key={collection.collectionTag}
                            collectionTag={collection.collectionTag}
                            collectionName={collection.collectionName}
                            items={collection.collectionItems}
                            description={collection.description}
                            listClass={'list'}
                            navigate={navigate}
                        />
                    ))) :

                    <LoadingClassesShimmer />}
            </div>
        </div>
    )
}

export default BrowseCollections;

