import React, { Component, Fragment } from "react";
import "./ClasslistView.css";
import { MdNewReleases } from "react-icons/lib/md";
import "theme/styles/react-select.min.css"
import { Translate } from "utils/Translate";
import config from "assets/config";
import ChannelHome from "containers/Channels/ChannelsHome"
import ConnectHome from "containers/LiveConnect/ClubPage";
import LiveStreamingHome from "containers/LiveStreaming/LiveStreamHome";
import logger from "utils/logger"

import ClassOfTheDay from 'components/PlayerAndCarousel/ClassOfTheDay/ClassOfTheDay'
import BrowseClasses from "containers/BrowseClasses/BrowseClasses"
import CollectionClasses from "containers/collections/CollectionClasses"
import LiveScheduleShimmer from "components/Shimmer/LiveScheduleShimmer";
import ClassOfTheDayShimmer from "components/Shimmer/ClassOfTheDayShimmer";
import BrowseClassesShimmer from "components/Shimmer/BrowseClassesShimmer";
import CollectionClassesShimmer from "components/Shimmer/CollectionClassesShimmer";
import ChannelsShimmer from "components/Shimmer/ChannelsShimmer";
class ClasslistView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      collection: [],
      clubClasses: [],
      recordType: "",
      channels: [],
      cotd: [],
    };
    this.collectionNameArray = [];

  }

  componentDidMount() {
    this.props.resetBrowseClassData()
    this.props.setClassesSearchData()
    this.props.setPaginatedData()
    this.props.resetFilters()
    if (config.showLiveEvent) { this.props.FetchLSConnectHome() }
    !config.showLiveEvent && config.ShowLiveConnect && this.props.fetchClubLiveConnectClasses();
    this.props.clearClassData();
    this.setState({ loading: true });
    var userId = localStorage.getItem("userId");

    if (userId !== null || userId !== undefined) {
      logger(this.props.location)
      this.setState({
        showChannelData: true
      })

      if (this.props.isNewUser === false && this.props.isSubscriptionLoading === false) {
        this.props.loadUserSubscription();
      }
      this.props.onOnDemandClassesLoad(userId);
      if (config.showClassOfTheDay)
        this.props.onCotdHomePageData(userId);
    } else {
      this.setState({
        showChannelData: false
      })
      this.props.onOnDemandClassesLoad("");
      if (config.showClassOfTheDay)
        this.props.onCotdHomePageData("");
    }

    this.setState({ classes: [] });

    if (config.showChannels) {
      this.props.fetchAllChannels();
    }
  }
  componentWillUnmount() {
    this.props.resetFllOndemand()
  }
  componentDidUpdate(prevProps) {
    if (prevProps.clubClasses !== this.props.clubClasses) {
      this.setState({ clubClasses: this.props.clubClasses?.data, recordType: this.props.clubClasses?.recordType })
    }
    if (prevProps.fllOnDemand !== this.props.fllOnDemand) {
      if (this.props.fllOnDemand) {
        this.setState({ clubClasses: this.props.fllOnDemand.items, recordType: "Connect" })
      }
    }
    if (prevProps.collection !== this.props.collection && this.props.collection !== null) {
      this.setState({ collection: this.props.collection })
    }
    if (prevProps.cotdHomePageData !== this.props.cotdHomePageData && this.props.cotdHomePageData !== null) {
      this.setState({ cotd: this.props.cotdHomePageData })
    }

    /// observer channels changes
    if (prevProps.allChannels !== this.props.allChannels) {
      this.updateChannelsState()
    }
  }

  updateChannelsState = () => {
    if (this.props.allChannels && this.props.allChannels.length > 0) {
      /// get top 5 channels
      var slicedArray = this.props.allChannels.slice(0, 5);

      this.setState({ channels: slicedArray })
    }
  };

  browseCollectionClick = () => {
    this.GetCollectionTags(this.props.collection);
    this.props.navigate(
      "/collections",
      {state: { collectionArray: this.collectionNameArray },
    });
  };
  GetCollectionTags(itemCollection) {
    if (itemCollection !== null || itemCollection.length !== 0) {
      let lookup = {};
      for (var item, i = 0; (item = itemCollection[i++]);) {
        var name = item.collectionName;
        if (!(name in lookup)) {
          lookup[name] = 1;
          this.collectionNameArray.push(name);
        }
      }
    }
  }

  render() {
    /* Server error */
// eslint-disable-next-line no-unused-vars
    let classes;
    if (this.state.collection.length === 0 && !this.state.loading) {
      classes = (
        <div className="flex" style={{ marginTop: "20px" }}>
          <MdNewReleases
            style={{
              width: "60px",
              height: "60px",
              margin: "0 10px",
              display: "none",
            }}
          />
          <h4>
            {Translate({ id: "ClassListViews.NoClassesFound" })}
          </h4>
        </div>
      );
    }
    var collectionArray = this.state.collection;
    var channelData = this.state.channels;
    var cotdObj = this.state.cotd;


    return (
      <Fragment>
        <div className="page-container">
          {
            this.props.liveConnectLoading ? <div className='m-t-30'><LiveScheduleShimmer /></div> :
              this.state.clubClasses && this.state.clubClasses.length > 0 ?
                config.showLiveEvent ?
                  this.state.recordType === "Live" ? <LiveStreamingHome clubClasses={this.state.clubClasses} /> :
                    this.state.recordType === "Connect" ? <ConnectHome clubClasses={this.state.clubClasses} /> : null
                  : config.ShowLiveConnect ? <ConnectHome clubClasses={this.state.clubClasses} /> : null
                : null
          }

            {this.props.cotdLoading ? <ClassOfTheDayShimmer /> : <ClassOfTheDay cotdObj={cotdObj} />}
            {(this.props.loading || this.props.fetchUserDetailsLoading) ? <BrowseClassesShimmer /> : ( config.showBrowseClasses === true || config.showBrowseClasses === undefined ) ? <BrowseClasses showArrow={true} /> : null}
            {(this.props.loading || this.props.fetchUserDetailsLoading) ? <ChannelsShimmer /> : channelData && channelData.length > 0 && config.showChannels === true
              ?
              (<ChannelHome
                channelData={channelData}
              />) : (null)}
            {(this.props.loading || this.props.fetchUserDetailsLoading) ? <CollectionClassesShimmer /> : config.showCollections && <CollectionClasses collectionArray={collectionArray} />}
          {/* <Fade
            in={this.props.loading || this.props.fetchUserDetailsLoading}
            style={{
              transitionDelay: this.props.loading ? "800ms" : "0ms",
              backgroundColor: "transparent",
            }}
            unmountOnExit
          >
            <Spinner backColor="#fff" />
          </Fade> */}
        </div>
      </Fragment>
    );
  }
}

export default ClasslistView;
