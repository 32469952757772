import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import {SpinnerConstants} from "../../utils/constants"

const Spinner = (props) => {
  const { size = SpinnerConstants.SIZE, thickness = SpinnerConstants.THICKNESS,areCards  } = props;
  return (
      <div style={{textAlign:"center"}} className="classes-spinner">
            {!areCards && (
              <CircularProgress
                label="lable"
                size={size}
                // className={this.props.classes.progress}
                thickness={thickness}
                color="secondary"
              />
            )}
      </div>
  );
}

export default Spinner;
