import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Translate } from "../../../utils/Translate";
import Routes from "../../../components/Router/Routes";
import {
  getMediaScreenResolutionforMobile,
  getTime,
} from "../../../utils/utilityfunctions";
const PlaylistItem = (props) => {
  const { item } = props;

  let navigate = useNavigate();
  const params =
    item?.totalItems === 1
      ? {
          id: "Playlist.PlaylistClasses_one",
          values: { count: item?.totalItems },
          defaultMessage: "{count} class",
        }
      : {
          id: "Playlist.PlaylistClasses_other",
          values: { count: item?.totalItems },
          defaultMessage: "{count} classes",
        };
  const navigateToPlayListDetails = () => {
    navigate(Routes.userPlayList + "/" + item.playlistId);
  };

  return (
    <div
      onClick={navigateToPlayListDetails}
      data-testid={item.playlistId}
      className="play-list-item"
    >
      <div className="play-list-item-image playerlist-image">
        <img alt="asd" className="thumb-xs" src={item?.imageLink} />
      </div>
      <div className="play-list-item-class player-right-side">
        <div className="play-list-item-class-info playlist-details-classes-lists">
          <h4 className="h4-title class-details-title">{item?.title}</h4>
          <h3 className="h3-title details-h3-title">{Translate(params)} </h3>
          <span className="play-list-item-class-info-time text-gray time-title">
            {getTime(item?.totalDuration)}
          </span>
        </div>
        <div className="play-list-item-class-info end playerlist-mobile-view">
          <h3>{item?.title}</h3>
          <span className="play-list-item-class-info-desc text-gray">
            {item?.description}
          </span>
        </div>
      </div>
    </div>
  );
};

PlaylistItem.propTypes = {
  item: PropTypes.object,
};

export default PlaylistItem;
