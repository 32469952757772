import * as actionTypes from "../actions/actionTypes";

const initialState = {
  list: [],
  loading: false,
  error: false,
  msg: "",
  titles: [],
  info: {
    list: [],
    title: "",
    description: "",
    loading: false,
    error: false,
    msg: "",
    modal: false,
    modalDelete: false,
    isPlayListRemoved: false,
    isClassRemoved: false,
    selectedRow: null,
  },
  playerWasOnFullScreen: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PLAY_LIST_START:
    case actionTypes.PLAY_LIST_SUCCESS:
    case actionTypes.PLAY_LIST_END:
    case actionTypes.PLAY_LIST_ERROR:
    case actionTypes.PLAY_LIST_CREATED_SUCCESS:
    case actionTypes.PLAY_LIST_UPDATED_SUCCESS:
    case actionTypes.PLAY_LIST_REORDER_SUCCESS:
    case actionTypes.PLAY_LIST_MSG_CLEAR:
      return { ...state, ...action.payload };
    case actionTypes.PLAY_LIST_INFO_START:
    case actionTypes.PLAY_LIST_INFO_LIST_SUCCESS:
    case actionTypes.PLAY_LIST_INFO_UPDATE:
    case actionTypes.PLAY_LIST_INFO_DELETE:
    case actionTypes.PLAY_LIST_INFO_SHOW_MODAL:
    case actionTypes.PLAY_LIST_INFO_END:
    case actionTypes.PLAY_LIST_INFO_ERROR:
      return { ...state, info: { ...state.info, ...action.payload } };
    case actionTypes.PLAYER_ON_FULLSCREEN:
      return { ...state, playerWasOnFullScreen: action.payload };
    default:
      return state;
  }
};
export default reducer;
