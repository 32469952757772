import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  addPlayList,
  getPlayList,
  addItemIntoPlayList,
  setPlayerWasOnFullScreen,
  getItemsOfPlayerPlayList,
} from "../store/actions/playList";
import { isEmpty } from "lodash";
import * as actionTypes from "../store/actions/actionTypes";
import { usePlayList } from "../hooks/usePlayList";
import { FetchPlayBackLink } from "../store/actions/ondemand";
const usePlayListPlayer = (playlistId) => {
  const tenantID = useSelector((state) => state.client?.data?.tenantID ?? "");
  const dispatch = useDispatch();

  const usePlayListPlayerDispatch = () => ({
    classClosed: () => dispatch({ type: actionTypes.CLASS_CLOSED }),
    classStarted: () => dispatch({ type: actionTypes.CLASS_STARTED }),
    fetchPlayListInfo: (playlistId) =>
      dispatch(getItemsOfPlayerPlayList(tenantID, playlistId)),
    setPlayerWasOnFullScreen: (onfullscreen) =>
      dispatch(setPlayerWasOnFullScreen(onfullscreen)),
  });
  const usePlayListState = () => ({
    isSubscriptionLoading: useSelector(
      (state) => state.subscription.isSubscriptionLoading
    ),
    streamingVideoLink: useSelector(
      (state) => state.onDemand?.StreamingVideoLink
    ),
    isStreamingLinkLoading: useSelector((state) => state.onDemand?.loading),
    hasPlayerAccess: useSelector((state) => state.subscription.hasPlayerAccess),
  });
  return { usePlayListPlayerDispatch, tenantID, usePlayListState };
};

export { usePlayListPlayer };
