import { ClassType } from "utils/constants";
import { generateShortDynamicLink } from "utils/Deeplinking/GenerateDeepLinkService";
import * as actionTypes from "./actionTypes";

export const getShortDeepLink = (classType, classDetails, tenantConfig) => {
  return (dispatch) => {
    //dispatch({ type: actionTypes.FETCH_DYNAMIC_DEEPLINK_START });

    let url = tenantConfig.url;   //"https://wexer-demo-fac-webplayer-test.web.app";//tenantConfig.url; //from tenant config

    switch (classType) {
      default:
      case ClassType.CLASSES:
        url = url + `/classes/${classDetails.classId}`;
        break;
      case ClassType.COLLECTIONS:
        url = url + `/collections/${classDetails.classId}`;
        break;
      case ClassType.LIVE_SIMULATED_SVOD:
        url = url + `/connect/live/${classDetails.classId}`;
        break;
    }
    let env = "";
    if (process.env.REACT_APP_PLATFORM === "FF-INT") {
      env = "test";
    } else if (process.env.REACT_APP_PLATFORM === "STAGE") {
      env = "stage";
    }
     else if (process.env.REACT_APP_PLATFORM === "PROD") {
      env = "prod";
    }

    let domain=''
    if(tenantConfig?.dynamicLinkDomain && tenantConfig?.dynamicLinkDomain.length>0){
      domain= `https://${tenantConfig.dynamicLinkDomain}${env}`
    }
    else{
      domain= `https://${tenantConfig.tenantID}${env}`;
    }
    let webApiKey = process.env.REACT_APP_API_KEY;
    let longUrl =''

    if(tenantConfig?.dynamicLinkAllowedOnApp){
      let packageName = `com.connectedfitnesslabs.${tenantConfig.tenantID}`
      let isi = tenantConfig.appStoreId //apple's App Store ID
      longUrl = `${domain}.page.link/?link=${url}&apn=${packageName}&isi=${isi}&ibi=${packageName}&st=${classDetails.title}&sd=${classDetails.description}&si=${classDetails.imgLink}&efr=1`
    }
    if(!tenantConfig?.dynamicLinkAllowedOnApp){
        longUrl = `${domain}.page.link/?link=${url}&st=${classDetails.title}&sd=${classDetails.description}&si=${classDetails.imgLink}&efr=1`;
    }

    generateShortDynamicLink(webApiKey, longUrl)
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: actionTypes.FETCH_DYNAMIC_DEEPLINK_SUCCESS,
            shortLink: response.data.shortLink,
          });
        } else {
          dispatch({
            type: actionTypes.FETCH_DYNAMIC_DEEPLINK_FAIL,
          });
        }
      })
      .catch((error) => {
        console.log("GenerateDeepLink error ", error);
        dispatch({
          type: actionTypes.FETCH_DYNAMIC_DEEPLINK_FAIL,
        });
      });
  };
};


export const resetShortDeepLink = () => {
  return (dispatch) => {
     dispatch({
       type: actionTypes.FETCH_DYNAMIC_DEEPLINK_SUCCESS,
       shortLink: null,
     });
  }
}