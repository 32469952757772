import * as actionTypes from "./actionTypes";
import * as APIConstants from "../APIConstants";
import userApi from "../../userAPI";
import { filter, find, includes, map, reject } from "lodash";
import { apiStatusCode, PlayList } from "../../utils/constants";
import { getTranslatedFile } from "../../utils/utilityfunctions";

export const getPlayList = (tenantId) => {
  return (dispatch, getState) => {
    const {
      playList: { loading, info },
    } = getState();
    const infoMsg =
      !info.error &&
      includes([PlayList.msg.deleted, PlayList.msg.classDeleted], info.msg)
        ? { msg: info.msg }
        : { msg: "" };
    if (!loading)
      dispatch({
        type: actionTypes.PLAY_LIST_START,
        payload: {
          loading: true,
          msg: "",
          error: false,
          titles: [],
          info: { ...info, error: false, loading: false, msg: infoMsg.msg },
        },
      });
    const url = APIConstants.fetchPlayList;
    const body = { tenantId };
    userApi
      .post(url, body)
      .then((response) => {
        if (includes(apiStatusCode.success, response.status))
          dispatch({
            type: actionTypes.PLAY_LIST_SUCCESS,
            payload: {
              error: false,
              msg: PlayList.msg.get,
              list: response?.data ?? [],
            },
          });
        else throw new Error(response?.data);
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.PLAY_LIST_ERROR,
          payload: { error: true, msg: PlayList.msg.error },
        });
      })
      .finally(() => {
        dispatch({
          type: actionTypes.PLAY_LIST_END,
          payload: { loading: false, info: { ...info, msg: "" } },
        });
      });
  };
};
export const addPlayList = (tenantId, name, contentId) => {
  return (dispatch, getState) => {
    const {
      playList: { loading },
    } = getState();
    if (!loading)
      dispatch({
        type: actionTypes.PLAY_LIST_START,
        payload: { loading: true, msg: "", error: false, titles: [] },
      });
    const url = APIConstants.createPlayList;
    const body = { tenantId, name, contentId };
    userApi
      .post(url, body)
      .then((res) => {
        if (includes(apiStatusCode.success, res.status))
          dispatch({
            type: actionTypes.PLAY_LIST_CREATED_SUCCESS,
            payload: {
              error: false,
              msg: PlayList.msg.created,
              titles: [name],
            },
          });
        else throw new Error(res?.data);
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.PLAY_LIST_ERROR,
          payload: { error: true, msg: PlayList.msg.error },
        });
      })
      .finally(() => {
        dispatch({
          type: actionTypes.PLAY_LIST_END,
          payload: { loading: false },
        });
        setTimeout(() => dispatch(clearMsg()), PlayList.notification.showTime);
      });
  };
};
export const addItemIntoPlayList = (tenantId, playlists, selectedTitles) => {
  return (dispatch, getState) => {
    const {
      playList: { loading },
    } = getState();
    if (!loading)
      dispatch({
        type: actionTypes.PLAY_LIST_START,
        payload: { loading: true, msg: "", error: false, titles: [] },
      });
    const url = APIConstants.addItemToPlayList;
    const body = { tenantId, playlists };
    userApi
      .post(url, body)
      .then((res) => {
        if (includes(apiStatusCode.success, res.status)) {
          dispatch({
            type: actionTypes.PLAY_LIST_UPDATED_SUCCESS,
            payload: {
              error: false,
              msg: PlayList.msg.updated,
              titles: selectedTitles,
            },
          });
        } else throw new Error(res?.data);
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.PLAY_LIST_ERROR,
          payload: { error: true, msg: PlayList.msg.error },
        });
      })
      .finally(() => {
        dispatch({
          type: actionTypes.PLAY_LIST_END,
          payload: { loading: false },
        });
        setTimeout(() => dispatch(clearMsg()), PlayList.notification.showTime);
      });
  };
};
const getTitleDesc = (tenantId, playlistId) => {
  const url = APIConstants.fetchPlayList;
  const body = { tenantId };
  return new Promise((resolve, rej) => {
    userApi.post(url, body).then((res) => {
      try {
        if (includes(apiStatusCode.success, res.status)) {
          const { title, description } = find(res.data, { playlistId });
          resolve({ title, description });
        } else rej(res?.data);
      } catch (e) {
        rej(e.message);
      }
    });
  });
};
export const getItemsOfPlayList = (tenantId, playlistId) => {
  return (dispatch, getState) => {
    const {
      playList: {
        info: { loading },
      },
    } = getState();
    if (!loading)
      dispatch({
        type: actionTypes.PLAY_LIST_INFO_START,
        payload: { loading: true, msg: "", error: false },
      });
    const url = APIConstants.fetchPlayListInfo;
    const body = { tenantId, playlistId };
    getTitleDesc(tenantId, playlistId)
      .then((result) => {
        userApi
          .post(url, body)
          .then((response) => {
            if (includes(apiStatusCode.success, response.status))
              return response?.data ?? [];
            else throw new Error(response?.data);
          })
          .then((list) => {
            const { title, description } = result;
            dispatch({
              type: actionTypes.PLAY_LIST_INFO_LIST_SUCCESS,
              payload: {
                error: false,
                msg: PlayList.msg.get,
                list,
                title,
                description,
              },
            });
          })
          .catch((e) => {
            dispatch({
              type: actionTypes.PLAY_LIST_INFO_ERROR,
              payload: { error: true, msg: PlayList.msg.error },
            });
          })
          .finally(() => {
            dispatch({
              type: actionTypes.PLAY_LIST_INFO_END,
              payload: { loading: false },
            });
          });
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.PLAY_LIST_INFO_ERROR,
          payload: { error: true, msg: PlayList.msg.error },
        });
      });
  };
};
export const getItemsOfPlayerPlayList = (tenantId, playlistId) => {
  return (dispatch, getState) => {
    const {
      playList: {
        info: { loading },
      },
    } = getState();
    if (!loading)
      dispatch({
        type: actionTypes.PLAY_LIST_INFO_START,
        payload: { loading: true, msg: "", error: false },
      });
    const url = APIConstants.fetchPlayListInfo;
    const body = { tenantId, playlistId };
    userApi
      .post(url, body)
      .then((response) => {
        if (includes(apiStatusCode.success, response.status))
          return response?.data ?? [];
        else throw new Error(response?.data);
      })
      .then((list) => {
        dispatch({
          type: actionTypes.PLAY_LIST_INFO_LIST_SUCCESS,
          payload: { error: false, msg: PlayList.msg.get, list },
        });
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.PLAY_LIST_INFO_ERROR,
          payload: { error: true, msg: PlayList.msg.error },
        });
      })
      .finally(() => {
        dispatch({
          type: actionTypes.PLAY_LIST_INFO_END,
          payload: { loading: false },
        });
      });
  };
};

export const getItemsStreamingLinkOfPlayList = (tenantId, playlistId) => {
  return (dispatch, getState) => {
    const {
      playList: {
        info: { loading },
      },
    } = getState();
    if (!loading)
      dispatch({
        type: actionTypes.PLAY_LIST_INFO_START,
        payload: { loading: true, msg: "", error: false },
      });
    const url = APIConstants.accessPlayListItems;
    const body = { tenantId, playlistId };
    userApi
      .put(url, body)
      .then((response) => {
        if (includes(apiStatusCode.success, response.status))
          return response?.data ?? [];
        else throw new Error(response?.data);
      })
      .then((list) => {
        // const {title,description} = result;
        dispatch({
          type: actionTypes.PLAY_LIST_INFO_LIST_SUCCESS,
          payload: { error: false, msg: PlayList.msg.get, list },
        });
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.PLAY_LIST_INFO_ERROR,
          payload: { error: true, msg: PlayList.msg.error },
        });
      })
      .finally(() => {
        dispatch({
          type: actionTypes.PLAY_LIST_INFO_END,
          payload: { loading: false },
        });
      });
  };
};
export const setItemsOfPlayList = (tenantId, playlistId, list) => {
  return (dispatch) => {
    const url = APIConstants.reorderPlayListInfo;
    const playlistItemId = map(list, "playListItemId");
    const body = { tenantId, playlistId, playlistItemId };
    dispatch({
      type: actionTypes.PLAY_LIST_INFO_UPDATE,
      payload: { list, msg: "" },
    });
    userApi
      .put(url, body)
      .then((response) => {
        if (includes(apiStatusCode.success, response.status))
          dispatch({
            type: actionTypes.PLAY_LIST_INFO_UPDATE,
            payload: { error: false },
          });
        else throw new Error(response?.data);
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.PLAY_LIST_INFO_ERROR,
          payload: { error: true, msg: PlayList.msg.error },
        });
        dispatch(getItemsOfPlayList(tenantId, playlistId));
      });
  };
};
export const PlayListInfoModal =
  (isOpen = false, key = "edit", selectedRow) =>
  (dispatch) => {
    let payload = { modal: isOpen, selectedRow: null };
    if (key === "delete") payload = { modalDelete: isOpen, selectedRow: null };
    if (selectedRow) payload = { ...payload, selectedRow };
    dispatch({
      type: actionTypes.PLAY_LIST_INFO_SHOW_MODAL,
      payload,
    });
  };
export const updatePlayList = (tenantId, name, description, playlistId) => {
  return (dispatch, getState) => {
    const {
      playList: { loading },
    } = getState();
    if (!loading)
      dispatch({
        type: actionTypes.PLAY_LIST_INFO_START,
        payload: { loading: true, msg: "", error: false },
      });
    const url = APIConstants.updatePlayList;
    const body = { tenantId, name, description, playlistId };
    userApi
      .post(url, body)
      .then((res) => {
        if (includes(apiStatusCode.success, res.status))
          dispatch({
            type: actionTypes.PLAY_LIST_INFO_UPDATE,
            payload: {
              error: false,
              msg: PlayList.msg.updated,
              title: name,
              description,
            },
          });
        else throw new Error();
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.PLAY_LIST_INFO_ERROR,
          payload: { error: true, msg: PlayList.msg.error },
        });
      })
      .finally(() => {
        // const {playList: { msg } } = getState();
        // if(msg === PlayList.msg.created){
        //     dispatch(getPlayList(tenantId))
        // }else{
        dispatch({
          type: actionTypes.PLAY_LIST_INFO_SHOW_MODAL,
          payload: { loading: false, modal: false },
        });
        // }
      });
  };
};
export const removePlayList = (tenantId, playlistId) => {
  return (dispatch, getState) => {
    const {
      playList: { loading },
    } = getState();
    if (!loading)
      dispatch({
        type: actionTypes.PLAY_LIST_INFO_START,
        payload: { loading: true, msg: "", error: false },
      });
    const url = APIConstants.deletePlayList;
    const data = { tenantId, playlistId };
    userApi
      .delete(url, {}, { data })
      .then((res) => {
        if (includes(apiStatusCode.success, res.status))
          dispatch({
            type: actionTypes.PLAY_LIST_INFO_DELETE,
            payload: {
              error: false,
              msg: PlayList.msg.deleted,
              list: filterList,
              isPlayListRemoved: true,
            },
          });
        else throw new Error();
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.PLAY_LIST_INFO_ERROR,
          payload: { error: true, msg: PlayList.msg.error },
        });
      })
      .finally(() => {
        // const {playList: { msg } } = getState();
        // if(msg === PlayList.msg.created){
        //     dispatch(getPlayList(tenantId))
        // }else{
        // LOADING FALSE IN GET PLAYLIST
        dispatch({
          type: actionTypes.PLAY_LIST_INFO_SHOW_MODAL,
          payload: {
            loading: false,
            modalDelete: false,
            isPlayListRemoved: false,
          },
        });
        // }
      });
  };
};
export const removeClassFromPlayList = (tenantId, playlistId) => {
  return (dispatch, getState) => {
    const {
      playList: {
        info: { loading, selectedRow, list },
      },
    } = getState();
    const playlistItemId = [selectedRow?.playListItemId];
    const filterList = reject(list, {
      playListItemId: selectedRow?.playListItemId,
    });
    if (!loading)
      dispatch({
        type: actionTypes.PLAY_LIST_INFO_START,
        payload: { loading: true, msg: "", error: false },
      });
    const url = APIConstants.deleteItemToPlayList;
    const data = { tenantId, playlistId, playlistItemId: playlistItemId };

    userApi
      .delete(url, {}, { data })
      .then((res) => {
        if (includes(apiStatusCode.success, res.status))
          dispatch({
            type: actionTypes.PLAY_LIST_INFO_DELETE,
            payload: {
              error: false,
              msg: PlayList.msg.classDeleted,
              list: filterList,
              isClassRemoved: true,
            },
          });
        else throw new Error();
      })
      .catch((e) => {
        dispatch({
          type: actionTypes.PLAY_LIST_INFO_ERROR,
          payload: { error: true, msg: PlayList.msg.error },
        });
      })
      .finally(() => {
        // const {playList: { msg } } = getState();
        // if(msg === PlayList.msg.created){
        //     dispatch(getPlayList(tenantId))
        // }else{
        dispatch({
          type: actionTypes.PLAY_LIST_INFO_SHOW_MODAL,
          payload: {
            loading: false,
            modalDelete: false,
            isClassRemoved: false,
          },
        });
        // }
      });
  };
};
export const clearMsg = () => (dispatch) => {
  dispatch({
    type: actionTypes.PLAY_LIST_MSG_CLEAR,
    payload: { msg: "" },
  });
};
export const setPlayerWasOnFullScreen =
  (playerWasOnFullScreen) => (dispatch) => {
    dispatch({
      type: actionTypes.PLAYER_ON_FULLSCREEN,
      payload: playerWasOnFullScreen,
    });
  };
