import React from "react";
import Button from "@material-ui/core/Button";
import { Translate } from "../utils/Translate";
import AppBarButtonStyle from "../theme/styles/AppBarButtonStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import { Grid, Typography } from "@material-ui/core";
import AccountButtonDotsMobile from "../components/AccountButtonDotsMobile";
import { isEmbeddedMode } from "../utils/utilityfunctions";
var config = require("../assets/config.json");

// const style = {
//   buttonLoginMobile:{ ['@media (min-width:780px)']:{
//     textTransform: 'none',
//     width: '64px',
//     height: '30px',
//     borderRadius: '15px',
//     padding: '4px 8px'
//   }
// }
// }

class AccountButtons extends React.Component {
  hideSignUpButton() {
    if (config.showSignUpPage === false || isEmbeddedMode()) {
      return true
    }
    else {
      return false
    }
  }
  hideLoginButton() {
    if (isEmbeddedMode()) {
      return true
    }
    else {
      return false
    }
  }
  onClickHandler = () => {
    if(config.mmsSignupLink  === null || config.mmsSignupLink  === undefined){
      this.props.navigate("/signup")
    }
    else{
      window.open(config.mmsSignupLink, '_blank');
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="loginButtonHolder">
          <Grid container justifyContent="flex-end" style={{ height: "inherit", alignItems: "center" }}>
          {this.hideSignUpButton() ? (<div></div>) : (
              <Grid item
                variant="contained"
                // color="secondary"
                className={this.props.classes.AccountBarButton}
                style={
                  {
                    // ...FontStyle.SFProDisplaySmall,
                    paddingRight: "16px",
                    cursor: "pointer"
                  }}
                onClick={this.onClickHandler}
              >
                <Typography variant="button">{Translate({ id: "layout.SignUp" })}</Typography>
              </Grid>)}
              {this.hideLoginButton() ? (<div></div>) : (<Grid item>
            <Button className={`${this.props.classes.loginButton} btn-default`}
                style={{
                  // ...FontStyle.SFProDisplaySmall,                  
                }}
                color="secondary"
                variant="contained"
                onClick={() => {
                  this.props.navigate("/signin");
                }}
              >
                <Typography variant="button">
                  {Translate({ id: "layout.SignIn" })}
                </Typography>
              </Button>
            </Grid>)}
          </Grid>
        </div>

        <div className="loginButtonHolderMobile pull-right">
          <Grid container justifyContent="flex-end">
          {this.hideLoginButton() ? (<div></div>) : (<Grid item >
                <Typography style={{
                textTransform: 'none',
                width: '64px',
                height: '30px',
                padding: '4px 8px',
                marginTop: '6px'
              }} variant="h5" onClick={() => {
                  this.props.navigate( "/signup" );
                }}>
                  {Translate({ id: "Signup.Title" })}
                </Typography>
            </Grid>)}
          {this.hideSignUpButton() ? (<div></div>) : (<Grid item>
              <Button style={{
                textTransform: 'none',
                width: '64px',
                height: '30px',
                borderRadius: '15px',
                padding: '4px 8px',
                marginTop: '6px'
              }}
                color="secondary"
                variant="contained"
                onClick={() => {
                  this.props.navigate("/signin");
                }}
              >
                <Typography variant="h5">
                  {Translate({ id: "layout.SignIn" })}
                </Typography>
              </Button>
            </Grid>)}
            <Grid item style={{ paddingTop: "7px" }}>
              {config.showSignUpPage === false ? (<div></div>) :
                // <img
                //   className=""
                //   src={require("../images/more.svg")}
                //   onClick={() => {
                //     this.props.history.push({ pathname: "/options" });
                //   }}
                // />
                <AccountButtonDotsMobile></AccountButtonDotsMobile>
              }</Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}
export default withStyles(AppBarButtonStyle)(AccountButtons);

