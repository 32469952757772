import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { usePlayListInfo } from "../../../hooks/usePlayListInfo";
import { Translate } from "../../../utils/Translate";
import { map } from "lodash";
import { PlayListItemInfo } from "./PlayListItemInfo";
import { PlayListInfoShimmer } from "./PlayListInfo.Shimmer";
import { PlayListInfoDetails } from "./PlayListInfoDetails";
import "./PlayListInfo.scss";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from 'react-dnd-touch-backend';
import { Notification } from "../../PlayerAndCorousel/views/Player/PlayList/Notification";
import { PlayListInfoControlForMobile } from "./PlayListInfoControlForMobile";
import { useNavigate } from "react-router-dom";
import Routes from "../../../components/Router/Routes";

const PlayListInfo = (props) => {
  const { playlistId } = props;
  const {
    playListInfo,
    movePlayListItem,
    updatePlayListTitle,
    handelModal,
    showNotification,
    severity,
    deletePlayList,
    deleteClass,
  } = usePlayListInfo(playlistId);
  const navigate = useNavigate();


  if (playListInfo.error) {
    navigate(Routes.userPlayList);
  }
  const handelDelete = (item) => {
    handelModal(true, "delete", item);
  };
  const getMediaScreenResolution = () =>{
    const mediaQuery = window.matchMedia('(pointer: coarse)');
    return mediaQuery?.matches;
  }

  const isDisabledDel = playListInfo?.list?.length === 1;
  const { selectedRow, loading, title, description, modal, modalDelete, list } = playListInfo || {} ;
  return (
    <div className="m-t-xs-5 play-list-info">
      <PlayListInfoDetails
        {...{
          selectedRow,
          loading,
          title,
          description,
          modal,
          modalDelete,
          playlistId,
          list,
        }}
        handelModal={handelModal}
        updatePlayListTitle={updatePlayListTitle}
        deletePlayList={deletePlayList}
        deleteClass={deleteClass}
      />
      {playListInfo?.loading ? <PlayListInfoShimmer /> : null}
      {showNotification && (
        <Notification
          {...{ severity, text: playListInfo.msg, show: showNotification }}
        />
      )}
      <div className="playlist-page-container">
        <DndProvider backend={getMediaScreenResolution()?TouchBackend:HTML5Backend} >
          {!playListInfo?.loading && playListInfo?.list.length
            ? map(playListInfo.list, (item, index) => (
                <PlayListItemInfo
                  isDisabledDel={isDisabledDel}
                  handelDelete={handelDelete}
                  movePlayListItem={movePlayListItem}
                  key={index}
                  index={index}
                  item={item}
                />
              ))
            : null}
        </DndProvider>
      </div>
      <div className="play-list-info-controls for-mobile">
        <PlayListInfoControlForMobile
          {...{
            selectedRow,
            loading,
            title,
            description,
            modal,
            modalDelete,
            playlistId,
            list,
          }}
        />
      </div>
    </div>
  );
};

PlayListInfo.propTypes = {};
export { PlayListInfo };
