import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import Spinner from "./components/spinner/AppLoader";
import Localytics from "./hoc/LocalyticsContainer";
import { fetchTenantConfig, loadPoKeys } from "./store/actions/ondemand";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import theme from "./theme/theme";
import { setAppLoadStartEvent } from "./store/actions/webPlayer";
import Router from "components/Router/Router";
import EmbeddedWrapper from "EmbeddedWrapper";
import { useRouter } from "hooks/useRouter";

const AppWrapper = (props)=>{
  const { webPlayerStartTime , appLoadStartTime} = props;
  const getInitials = useCallback(()=>{webPlayerStartTime(appLoadStartTime)},[webPlayerStartTime,appLoadStartTime])
  useEffect(()=>{getInitials()},[getInitials])
  const routerInfo = useRouter();
    return (
      <React.Fragment>
        {props.tenantConfig == null ? (
          <Spinner
            fetchTenantConfig={props.fetchTenantConfig}
            loadPoKeys={props.loadPoKeys}
          />
        ) : (
          <Localytics>
            <MuiThemeProvider theme={theme}>
            <EmbeddedWrapper embededConfig={props.embededConfig}>
                <Router {...props} {...routerInfo} />
            </EmbeddedWrapper>
            </MuiThemeProvider>
          </Localytics>
        )}
      </React.Fragment>
    );
}

const mapStateToProps = (state) => {
  return {
    tenantConfig: state.onDemand.tenantConfig,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    webPlayerStartTime: (appLoadStartTime) =>
      dispatch(setAppLoadStartEvent(appLoadStartTime)),
    fetchTenantConfig: () => dispatch(fetchTenantConfig()),
    loadPoKeys: () => dispatch(loadPoKeys()),
  };
};

//export default App;
export default connect(mapStateToProps, mapDispatchToProps)(AppWrapper);
